import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CatalogService} from '../../../models/catalog.service';

@Component({
    selector: 'app-popup-souses-list',
    templateUrl: './popup-souses-list.component.html',
    styleUrls: ['./popup-souses-list.component.scss']
})
export class PopupSousesListComponent implements OnInit {
    private modalController: ModalController;
    text: any = 'text from server';
    textIsOpen = false;
    private catalogService: CatalogService;
    products: any[] = [];

    constructor(
        modalController: ModalController,
        catalogService: CatalogService
    ) {
        this.modalController = modalController;
        this.catalogService = catalogService;
    }

    ngOnInit() {
        this.products = this.catalogService.findByIdProperty('isSauce', true);
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    goToDelivery() {
        this.modalController.dismiss({action: 'goToDelivery'});
    }
}
