import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {AppService} from 'src/app/services/app.service';
import {UserService} from '../../models/user.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        private injector: Injector,
    ) {
    }

    handleError(error: Error) {
        const appService = this.injector.get(AppService);
        const userService = this.injector.get(UserService);
        console.error(error);
        const data = {
            userId: userService.user.userId,
            userToken: userService.user.id,
            userPhone: userService.user.phone,
            city: appService.cityName,
        };
        appService.logError({
            type: 'GlobalErrorHandler.handleError',
            title: error.message,
            data,
            error
        });
    }
}
