import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Address} from '../entity/address.entity';

@Injectable({
    providedIn: 'root',
})

export class AddressService extends MainService {

    public addresses: Address[] = [];

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    public getAddresses(callback: (status: boolean) => void, loading = false, force = true) {

        const data = {
            action: 'getAddresses'
        };

        if (force || this.addresses.length === 0) {
            //this.regJsonRequest(data, (status, address: Address[]) => { TODO Rus howto wrire models in data props?
            this.regJsonRequest(data, (status, data: any) => {
                if (status && data.status) {
                    this.addresses = data.result;
                }
                callback(status);
            }, false, loading);
        } else {
            callback(true);
        }

    }

    public updateAddress(address: Address, callback: (status: boolean) => void, loading = false) {
        const data = {
            address,
            action: 'updateAddress'
        };

        this.regJsonRequest(data, (status, answer) => {
            if (status) {
                this.getAddresses(s => {
                    callback(s);
                }, true);
            }
        }, false, loading);
    }

    public removeAddress(id: number, callback: (status: boolean) => void) {
        const data = {
            addressID: id,
            action: 'removeAddress'
        };

        this.regJsonRequest(data, (status, answer) => {

            if (status) {
                const addressIndex = this.addresses.findIndex(item => item.id === id);
                this.addresses.splice(addressIndex, 1);
            }

            callback(status);
        }, false, true);
    }

    clean() {
        this.addresses = [];
    }
}
