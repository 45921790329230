import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
} from '@angular/core';

import {IonRouterOutlet, NavController, Platform} from '@ionic/angular';
import {CatalogService} from './models/catalog.service';
import {AppService} from './services/app.service';
import {CartService} from './models/cart.service';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UserService} from './models/user.service';
import {NavigationService} from './services/navigation.service';
import {OrderStep} from './entity/cart.entity';
import {InfoService} from './models/info.service';
import {HistoryService} from './models/history.service';
import {SurveyService} from './models/survey.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {DeepLinkService} from './services/deep-link.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss', 'fonts.scss']
})
export class AppComponent implements OnInit {
    public selectedIndex = 0;

    public appPages = [
        {
            title: 'Меню',
            url: '',
            icon: 'menu'
        },
        {
            title: 'Акции',
            url: 'promos',
            icon: 'discount'
        },
        {
            title: 'Доставка',
            url: 'delivery',
            icon: 'delivery'
        },
        {
            title: 'Рестораны',
            url: 'restaurants/main',
            icon: 'contacts'
        },
        {
            title: 'О нас',
            url: 'about_us',
            icon: 'about'
        },
        {
            title: 'Профиль',
            url: 'profile/main',
            icon: 'registr'
        }
    ];

    @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;
    // @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

    private platform: Platform;
    public nav: NavController;
    public catalogService: CatalogService;
    public historyService: HistoryService;
    public appService: AppService;
    public cartService: CartService;
    private statusBar: StatusBar;
    public navigationService: NavigationService;
    private userService: UserService;
    private infoService: InfoService;
    public surveyService: SurveyService;
    public deepLinkService: DeepLinkService;
    public router: Router;
    private location: Location;

    private loadingCounter = 2;
    private update: { needUpdate: boolean, version: any } = null;
    socialLinks = null;
    readyToSplash = false;
    cdr: ChangeDetectorRef;

    constructor(
        platform: Platform,
        nav: NavController,
        catalogService: CatalogService,
        historyService: HistoryService,
        appService: AppService,
        cartService: CartService,
        statusBar: StatusBar,
        navigationService: NavigationService,
        userService: UserService,
        infoService: InfoService,
        surveyService: SurveyService,
        router: Router,
        location: Location,
        cdr: ChangeDetectorRef,
        deepLinkService: DeepLinkService
    ) {
        this.platform = platform;
        this.nav = nav;
        this.catalogService = catalogService;
        this.historyService = historyService;
        this.appService = appService;
        this.cartService = cartService;
        this.statusBar = statusBar;
        this.navigationService = navigationService;
        this.userService = userService;
        this.infoService = infoService;
        this.surveyService = surveyService;
        this.router = router;
        this.location = location;
        this.cdr = cdr;
        this.deepLinkService = deepLinkService;

        this.initializeApp();
    }

    finalPreparations(loadCatalog = true) {
        if (loadCatalog) {
            this.catalogService.getCatalog(() => {
                this.checkForLoading();
            });
        } else {
            this.checkForLoading();
        }

        if (this.userService.isLogin()) {
            this.getCountOrdersWithSurvey();
            this.appService.facebook.logEvent('auth');
            this.appService.addUserToPush(this.userService.user.userId);
            this.cartService.sync(OrderStep.Start);
        } else {

            if (this.appService.getGuestToken()) {
                this.cartService.sync(OrderStep.Start);
            } else {
                this.cartService.newCart();
            }
        }
    }

    makeInit() {

        this.appService.getVersion(() => {
            console.log('appVersion', this.appService.appVersionNumber);
            this.loadingCounter = 2;

            this.appService.initAppMetrika();

            this.userService.makeLogin((status) => {
                this.finalPreparations(status && !!this.userService.user?.defaultAddress);
            });

            this.checkAppVersion((result) => {
                this.update = result;
                this.checkForLoading();
            });
        });

        this.readyToSplash = true;
    }

    getCountOrdersWithSurvey() {
        const userPhone = this.userService.user.login;

        this.surveyService.getSurveyCount(userPhone, status => {
            if (status) {
                // this.checkForLoading();
            }
        });
    }

    checkForLoading() {
        this.loadingCounter--;

        if (this.loadingCounter === 0) {

            // todo: temp
            // this.update.needUpdate = false;

            if (this.update.needUpdate) {

                if (this.update.version.critical.toString() === '2') {
                    this.appService.blockBack = true;
                }

                this.navigationService.goToPage('update', true, this.update.version);
            } else {
                this.navigationService.goToPage('menu', true);
                // this.appService.startPage = false;
            }

            this.appService.appReady = true;
            this.appService.changeMenu();
        }
    }

    checkAppVersion(callback: (result: { needUpdate: boolean, version: any }) => void) {
        if (this.appService.isCordova()) {
            this.catalogService.loadVersion((data) => {
                if (parseFloat(data.critical) !== 0) {
                    const ver = this.appService.isIos() ? data.ios : data.android;
                    let versionCode = String(this.appService.appVersionNumber).replace(/\./g, '');

                    if (versionCode === 'browser') {
                        versionCode = '10000000000';
                        // versionCode = '1';
                    }

                    callback({
                        needUpdate: parseFloat(ver) > parseFloat(versionCode),
                        version: data
                    });
                } else {
                    callback({
                        needUpdate: false,
                        version: data
                    });
                }

            });
        } else {
            callback({
                needUpdate: false,
                version: null
            });
        }
    }

    initializeApp() {

        this.platform.ready().then(() => {

            // this.appService.showSplash();
            // this.appService.hideSplash();

            this.navigationService.routerOutlet = this.routerOutlet;

            this.appService.setStatusBar();

            this.platform.resume.subscribe(() => {
                this.statusBar.overlaysWebView(true);
                this.statusBar.overlaysWebView(false);
                this.appService.setStatusBar();
            });

            if (!this.appService.isDevice()) {
                this.readyToSplash = true;
                this.appService.deviceId = 'browsertoken';
            }

            this.appService.windowHeight = window.outerHeight;

            this.appService.initCrashAnalytics();

            console.log('facebook getApplicationId', this.appService.facebook.getApplicationId());

            if (this.appService.isCordova()) {
                this.appService.subscribePush(() => {
                    this.makeInit();
                }, (data) => {

                    if (data.isUpdateStopList) {
                        this.catalogService.getCatalogStopList(() => {
                            this.cdr.detectChanges();
                        }, this.userService.token);
                    }

                });
            } else {
                this.makeInit();
            }
        });

        this.platform.backButton.subscribeWithPriority(1, () => {

            if (this.appService.productModalIsOpened) {
                this.appService.closeProductModal();
            } else if (!this.appService.productModalIsOpened && !this.appService.isOpenPickerModal && this.location.isCurrentPathEqualTo('/menu')) {
                navigator['app'].exitApp();
            } else {
                this.navigationService.back();
            }
        });
    }

    ngOnInit() {
        this.socialLinks = this.catalogService?.socials;
    }

    openCitySelection() {
        this.navigationService.goToPage('city-selection');
    }

}
