import {Entity} from '../core/main.entity';

export class ProductOffer implements Entity {
    id: number;                  //
    name: string;                       // если
    subName: string;
    preview: string;
    imageUrl: string;
    imageUrlCard: string[];
    isStopList: boolean;
    size: string;                       // к значению добавить сантиметры (например, 40 см)
    weight: string;                     // к значению добавить граммы (например, 300 гр.)
    litrag: string;                     // к значению добавить миллилитры (например, 1000 мл.)
    gramm: string;
    detail: string;
    price: number;
    fprice: number;
    oldPrice: number;
    categoryId: number;
    quantity: number;                     // к значению добавить штуки (например, 1 шт.)
    selectSize: string;
    sizeType: number; // 1 - small, 2 - middle, 3 - large
    nutritionalValue: {
        proteins: string,
        fats: string,
        carbo: string,
        kkal: string
    };
    // tslint:disable-next-line:variable-name
    preview_text: string;
}
