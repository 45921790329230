import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {AddressSearchAnswer} from '../entity/addressSearchAPIAnswer.entity';
import {ApiResponse} from '../entity/apiResponse.entity';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeliveryService extends MainService {

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    getCityId() {
        const cityId = +localStorage.getItem('ru.z2s.app__city');
        return cityId === 0 ? 1 : 2;
    }

    getCoordZones(callback) {

        this.getRequest(
            {},
            (response: ApiResponse) => {
                callback(response.result);
            }, 'location/zones', true, true);
    }

    checkAddress(term, /*home,*/ callback: (result: ApiResponse<AddressSearchAnswer>) => void) {
        this.getRequest({
            term,
            // home,
        }, (response: ApiResponse) => {
            callback(response);
        }, 'location/confirm', false, true);

    }

    checkCoords(x, y, callback: (status: boolean, result: AddressSearchAnswer) => void) {

        this.getRequest({
            x,
            y
        }, (response: ApiResponse) => {
            callback(response.status, response.result);
        }, 'location/confirmByCoors', false, true);
    }
}
