import {
    Injectable,
    Injector, NgZone
} from '@angular/core';
import {Deeplinks} from '@ionic-native/deeplinks/ngx';
import {AppService} from './app.service';
import {filter, first} from 'rxjs/operators';
import {CatalogService} from '../models/catalog.service';
import {NavigationService} from './navigation.service';
import {ProductModalService} from './product-modal.service';
import {BranchService} from '../models/branch.service';
import {CartService} from '../models/cart.service';
import {UserService} from '../models/user.service';
import {PromoModalComponent} from '../components/promo-modal/promo-modal';

// for all APP, CATALOG
// https://z2s.ru/catalog/{catalog_slug}
// https://z2s.ru/catalog/{catalog_slug}/{product_slug}/
// https://z2s.ru/favorite/
// https://z2s.ru/restaurants/
// https://z2s.ru/restaurants/{branch_slug}/ + BRANCHES
// https://z2s.ru/delivery/
// https://z2s.ru/about/
// https://z2s.ru/actions
// https://z2s.ru/actions/{promo_slug}/
// https://z2s.ru/personal/cart/

enum RouteType {
    Menu,
    Category,
    // Favorite,
    Contacts,
    Branch,
    Delivery,
    // About,
    Actions,
    Promo,
    Cart,
}

interface Url {
    host: string; // host: "z2s.ru"
    path: string; // path: "/catalog/pizza/"
    scheme: string; // scheme: "https"
    url: string; // url: "https://z2s.ru/catalog/pizza/"
}

@Injectable({
    providedIn: 'root'
})
export class DeepLinkService {
    private readonly injector: Injector;
    private ngZone: any;
    public deepLinks: Deeplinks;
    private appService: AppService;
    private catalogService: CatalogService;
    private navigationService: NavigationService;
    private userService: UserService;
    private branchService: BranchService;
    private cartService: CartService;

    private url: Url = {host: null, path: null, scheme: null, url: null};
    private routeType: RouteType = null;

    constructor(
        ngZone: NgZone,
        injector: Injector,
        deepLinks: Deeplinks,
    ) {
        this.ngZone = ngZone;
        this.injector = injector;
        this.deepLinks = deepLinks;
        this.appService = this.injector.get(AppService);
        this.catalogService = this.injector.get(CatalogService);
        this.navigationService = this.injector.get(NavigationService);
        this.userService = this.injector.get(UserService);
        this.branchService = this.injector.get(BranchService);
        this.cartService = this.injector.get(CartService);

        this.appService.platform.ready().then(() => {
            this.appService.getWindow().IonicDeeplink?.route({}, (match) => {
                }, (noMatch) => {
                    // console.log('Deeplink NOmatch', noMatch);
                    this.openDeepLink(noMatch.$link);
                }
            );
            this.appService.getWindow().addEventListener('click', (event) => {
                if (event.target.nodeName === 'A') {
                    const eventUrl = new URL(event.target.href);
                    const protocol = eventUrl.protocol?.length && eventUrl.protocol[eventUrl.protocol?.length - 1] === ':' ?
                        eventUrl.protocol.slice(0, -1) :
                        eventUrl.protocol;
                    const url: Url = {
                        host: eventUrl.host,
                        path: eventUrl.pathname,
                        scheme: protocol,
                        url: eventUrl.href,
                    };

                    // console.log('a', event);
                    // console.log('a url', url);
                    event.preventDefault();
                    event.stopPropagation();

                    // Если в ссылке содержатся определенные хостнеймы, то мы пытаемся открыть ссылку как диплинк
                    // иначе открываем ссылку в inAppBrowser
                    if (['z2s.ru'].includes(url.host)) {
                        this.openDeepLink(url);
                    } else {
                        this.appService.openLink(url.url);
                    }
                }
            });
        });
    }

    openDeepLink(url: Url) {
        this.setUrl(url);
        this.routeType = this.getRoute();
        if (this.routeType === null) {
            this.appService.openLink(url.url);
        }
        this.getRouteReady()
            .then(() => {
                this.startRoute();
            })
            .catch((err) => {
                console.log('DeepLinkService.getRouteReady reject: not all Promises resolved', err);
            });
    }

    private setUrl(url) {
        this.url = url;
        let path = this.url.path;
        path = path?.length && path[0] === '/' ? path.substring(1) : path;
        path = path?.length && path[path.length - 1] === '/' ? path.slice(0, -1) : path;
        this.url.path = path;
    }

    private getRoute(): RouteType {

        const regexpList: { [key in RouteType]?: RegExp } = {
            [RouteType.Category]: /^catalog\/.+$/,
            // [RouteType.Favorite]: /^favorite$/,
            [RouteType.Contacts]: /^restaurants$/,
            [RouteType.Branch]: /^restaurants\/.+$/,
            [RouteType.Delivery]: /^delivery$/,
            // [RouteType.About]: /^about$/,
            [RouteType.Actions]: /^actions$/,
            [RouteType.Promo]: /^actions\/.+$/,
            [RouteType.Cart]: /^personal\/cart$/,
        };
        for (const key in regexpList) {
            if (regexpList.hasOwnProperty(key) && regexpList[key].test(this.url.path)) {
                return Number(key);
            }
        }
        return null;
    }

    private getRouteReady(): Promise<any> {
        const appReady = this.appService.appReadySubject.pipe(filter(v => v), first()).toPromise();
        const menuReady = this.appService.menuReadySubject.pipe(filter(v => v), first()).toPromise();

        return Promise.all([
            appReady,
            menuReady,
        ]);
    }

    private startRoute() {
        this.ngZone.run(() => {
            this.appService.closeModalPicker();

            switch (this.routeType) {
                case RouteType.Category: {
                    const path = this.url.path.split('/');

                    const categorySlug = path[1];
                    const subCategorySlug = path.length > 1 ? path[2] : null;
                    const productSlug = path[path.length - 1];

                    const category = this.catalogService.categories.find(item => item.slug === categorySlug);
                    const subCategory = subCategorySlug ? category.subCategories.find(item => item.slug === subCategorySlug) : null;
                    const product = category ? this.catalogService.mainProductArray.find(item => item.slug === productSlug) : null;


                    if (category) {
                        if (subCategory) {
                            this.navigationService.goToPage('category/' + category.id + '/' + subCategory.id);
                        } else {
                            this.navigationService.goToPage('category/' + category.id);
                        }
                        if (product) {
                            const productModalService = this.injector.get(ProductModalService);
                            productModalService.openProductModal(product);
                        }
                    }
                    break;
                }
                // case RouteType.Favorite: {
                //     this.navigationService.goToPage('favorite');
                //     break;
                // }
                case RouteType.Contacts: {
                    this.navigationService.goToPage('branches');
                    break;
                }
                case RouteType.Branch: {
                    this.navigationService.goToPage('branches');
                    this.branchService.isDataSubject.pipe(filter(v => v), first()).toPromise().then(() => {
                        const path = this.url.path.split('/');
                        const branchSlug = path[1];
                        const branch = this.branchService.data.find(i => i.slug === branchSlug);
                        if (branch) {
                            this.navigationService.goToPage('branches/' + branch.id);
                        }
                    });
                    break;
                }
                case RouteType.Delivery: {
                    this.navigationService.goToPage('delivery');
                    break;
                }
                // case RouteType.About: {
                //     this.navigationService.goToPage('about_us');
                //     break;
                // }
                case RouteType.Actions: {
                    this.navigationService.goToPage('promos');
                    break;
                }
                case RouteType.Promo: {
                    const path = this.url.path.split('/');
                    const promoSlug = path[1];
                    const promo = this.catalogService.promos.find(i => i.slug === promoSlug);

                    this.navigationService.goToPage('promos');
                    if (promo) {
                        this.appService.modalService.createModal(PromoModalComponent, {promoId: promo.id});
                    }
                    break;
                }
                case RouteType.Cart: {
                    this.navigationService.goToPage('cart');
                    break;
                }
                default:
                    this.navigationService.goToPage('menu', true);
                    break;
            }
        });
    }
}
