import {Injectable, Injector} from '@angular/core';
import {Subject} from 'rxjs';
import {Product} from '../entity/product.entity';
import {CatalogService} from '../models/catalog.service';
import {AppService} from './app.service';
import {ProductModalComponent} from '../components/product-modal/product-modal';

@Injectable({
    providedIn: 'root'
})
export class ProductModalService {

    public appService: AppService;
    catalogService: CatalogService;

    constructor(
        injector: Injector,
        appService: AppService,
        catalogService: CatalogService
    ) {
        this.appService = appService;
        this.catalogService = catalogService;
    }

    catalogProducts: any;
    product: Product;
    modalDishes = [];
    productCatId: number;
    initialSlide: number;
    modalTitle: string;
    selectedSideDish = '';

    dish: Product = new Product();
    offerId: number;

    eventUpdateQuantity: Subject<any> = new Subject<any>();
    eventSelectOfferInModal: Subject<any> = new Subject<any>();

    updateQuantity$ = this.eventUpdateQuantity.asObservable();
    selectOfferInModal$ = this.eventSelectOfferInModal.asObservable();

    openProductModal(
        product: any,
    ) {

        this.product = this.catalogService.mainProductArray.find(i => (i.id === product.id) && (product.categoryId === product.categoryId));
        if (product.offerId) {
            this.product.offerId = product.offerId;
            this.offerId = product.offerId;
        }
        this.productCatId = this.product.categoryId;
        this.catalogProducts = this.catalogService.products;

        this.getDishes();
        this.getActiveIdx();
        this.getModalTitle();

        this.appService.productModalIsOpened = true;
        this.appService.modalService.createModal(ProductModalComponent, {product: this.product});
    }

    updateItemQuantityInMenu() {
        this.eventUpdateQuantity.next();
    }

    getDishes() {
        if (this.product.subCategoryIndex === null) {
            for (const modalDish of this.catalogProducts[this.productCatId]) {
                if (modalDish.id != 0) {
                    this.modalDishes.push(modalDish);
                }
            }
        } else {
            this.modalDishes = this.catalogProducts[this.productCatId]
                .filter(dish => dish.subCategoryIndex === this.product.subCategoryIndex);
        }
    }

    getActiveIdx() {
        this.initialSlide = this.modalDishes.findIndex(elem => elem.id === this.product.id);
    }

    getModalTitle() {
        if (this.product.subCategoryIndex === null) {
            this.modalTitle = this.product.catName;
        } else {
            const currentCat = this.catalogService.categories.filter(category => category.id === this.product.categoryId)[0];
            this.modalTitle = currentCat.subCategories.filter(subCat => subCat.id === this.product.subCategoryIndex)[0].name;
        }
    }

    // Расчет стоимости


    /*
    * @TODO все манипуляции с товарами, расчет цен и тд, надо вынести в обьект продукта
    * */

    setActiveOffer(activeDish, offerId = null) {
        Object.assign(this.dish, activeDish);
        // this.offerId = offerId ? offerId : (activeDish.offers[activeDish.offers.length - 1].id);
        // this.offerId = offerId ? offerId : (activeDish.offerId);
        this.offerId = activeDish.offerId;

        this.eventSelectOfferInModal.next(this.offerId);
    }

}
