import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {CommentService} from '../../../models/comment.service';
import {Comment} from '../../../entity/comment.entity';
import {UserService} from '../../../models/user.service';

@Component({
    selector: 'app-popup-save-comment',
    templateUrl: './popup-save-comment.component.html',
    styleUrls: ['./popup-save-comment.component.scss'],
})
export class PopupSaveCommentComponent implements OnInit {
    @Input() text: string;

    private modalController: ModalController;
    private commentService: CommentService;
    private userService: UserService;
    private comment: Comment;

    constructor(
        modalController: ModalController,
        commentService: CommentService,
        userService: UserService,
    ) {
        this.modalController = modalController;
        this.commentService = commentService;
        this.userService = userService;
    }

    ngOnInit() {
        this.comment = new Comment();
        this.comment.name = 'template';
        if (this.text) {
            this.comment.text = this.text;
        }
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    saveComment() {
        this.commentService.updateComments(this.comment, (data) => {
            if (data) {
                this.modalController.dismiss({action: 'saved'});
            } else {
                this.modalController.dismiss({action: 'error save'});
            }
        }, true);
    }

    onCancel() {
        this.modalController.dismiss({action: 'canceled'});
    }
}
