import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppService} from 'src/app/services/app.service';
import {UserService} from 'src/app/models/user.service';
import {BranchService} from 'src/app/models/branch.service';
import {DeliveryService} from 'src/app/models/delivery.service';
import {SuggestionsService} from 'src/app/models/suggestions.service';

@Component({
    selector: 'app-popup-select-address',
    templateUrl: 'popup-select-address.html',
    styleUrls: ['popup-select-address.scss']
})
export class PopupSelectAddressComponent implements OnInit, OnDestroy {

    private modalController: ModalController;
    public appService: AppService;
    private suggestionsService: SuggestionsService;
    private deliveryService: DeliveryService;
    public branchService: BranchService;
    public userService: UserService;
    public cdr: ChangeDetectorRef;

    constructor(
        modalController: ModalController,
        appService: AppService,
        suggestionsService: SuggestionsService,
        deliveryService: DeliveryService,
        branchService: BranchService,
        userService: UserService,
        cdr: ChangeDetectorRef,
    ) {
        this.modalController = modalController;
        this.appService = appService;
        this.suggestionsService = suggestionsService;
        this.deliveryService = deliveryService;
        this.branchService = branchService;
        this.userService = userService;
        this.cdr = cdr;

    }

    ngOnInit() {
    }

    ionViewWillEnter() {
    }

    ngOnDestroy() {
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    onSaveDefaultAddress(event: 'address' | 'catalog') {
        if (event === 'address') {
            this.close();
        }
    }
}
