import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Comment} from '../entity/comment.entity';

@Injectable({
    providedIn: 'root',
})

export class CommentService extends MainService {

    public comments: Comment[] = [];

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    public getComments(callback: (status: boolean) => void, loading = false, force = true) {
        const data = {
            action: 'getComments'
        };

        if (force || this.comments.length === 0) {
            //this.regJsonRequest(data, (status, comments: Comment[]) => { TODO Rus howto wrire models in data props?
            this.regJsonRequest(data, (status, data: any) => {
                if (status && data.status) {
                    this.comments = data.result;
                }

                callback(status);
            }, false, loading);
        } else {
            callback(true);
        }
    }

    public updateComments(comment: Comment, callback: (status: boolean) => void, loading = false) {
        const data = {
            action: 'updateComment',
            comment
        };

        this.regJsonRequest(data, (status, answer) => {
            if (status) {
                this.getComments(res => {
                    callback(res);
                }, true);
            }
        }, false, loading);
    }

    public removeComment(id: number, callback: (status: boolean) => void) {
        const data = {
            action: 'removeComment',
            commentID: id
        };

        this.regJsonRequest(data, (status, answer) => {

            if (status) {
                const commentIndex = this.comments.findIndex(item => item.id.toString() === id.toString());
                this.comments.splice(commentIndex, 1);
            }

            callback(status);
        }, false, true);
    }

    clean() {
        this.comments = [];
    }
}
