import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'ruMonth'
})
export class MonthPipe implements PipeTransform {

    months = [
        'января',
        'февраля',
        'марта',
        'апреля',
        'мая',
        'июня',
        'июля',
        'августа',
        'сентября',
        'октября',
        'ноября',
        'декабря',
    ];

    transform(date: string): string {
        const dates = date.split('.')
        let d = dates[0] + ' ',
            m = +dates[1] - 1,
            y = ' ' + dates[2];

        return d + this.months[m] + y;
    }
}
