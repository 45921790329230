import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    OnDestroy,
    Input,
    ChangeDetectorRef
} from '@angular/core';
import {AppService} from '../../services/app.service';
import {CartService} from 'src/app/models/cart.service';
import {CatalogService} from 'src/app/models/catalog.service';
import {UserService} from '../../models/user.service';
import {DomSanitizer} from '@angular/platform-browser';
import {MainModal} from '../../core/main.modal';
import {ModalService} from '../../services/modal.service';
import {GestureController} from '@ionic/angular';
import Scrollbar from 'smooth-scrollbar';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';

@Component({
    selector: 'banner-modal',
    styleUrls: ['./banner-modal.scss'],
    templateUrl: 'banner-modal.html'
})
export class BannerModalComponent extends MainModal implements OnInit, OnDestroy {

    @Input() banner: any;

    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    @ViewChild('modalPromoItem', {read: ElementRef, static: false})
    modalPromoItemRef: ElementRef;

    @ViewChild('shadow', {static: false})
    shadowRef: ElementRef;

    @ViewChild('bannerDescription', {static: false})
    bannerDescriptionRef: ElementRef;

    public appService: AppService;
    public userService: UserService;
    private sanitization: DomSanitizer;
    cartService: CartService;
    catalogService: CatalogService;
    cdr: ChangeDetectorRef;


    opacity = 0;
    infoOpacity = 0;
    modalItemTop = 0;
    isIosParallax: boolean;
    private scrollBar: Scrollbar;

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        cartService: CartService,
        catalogService: CatalogService,
        sanitization: DomSanitizer,
        cdr: ChangeDetectorRef
    ) {
        super(appService, modalService, gestureCtrl);
        this.catalogService = catalogService;
        this.cartService = cartService;
        this.sanitization = sanitization;
        this.cdr = cdr;
    }

    ngOnInit() {
        this.getParallax();

        setTimeout(() => {
            this.createVirtualScroll(this.scrollContainer.nativeElement);
        });
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll);
        this.onScroll({offset: {x: 0, y: 0}});
    }

    ngOnDestroy() {
    }

    onScroll(e: any) {
        this.modalItemTop = e.offset.y;
        this.setStyleCardImg();
        this.modalService.scrollModal(this.modalItemTop);
    }

    setStyleCardImg() {
        if (this.modalItemTop > 20) {
            this.opacity = (this.modalItemTop - 20) / 130;
            this.infoOpacity = (this.modalItemTop) / 130;
        }

        if (this.modalItemTop === 0) {
            this.opacity = 0;
            this.infoOpacity = 0;
        }

        this.shadowRef.nativeElement.style.setProperty('opacity', `${this.opacity}`);
        this.bannerDescriptionRef.nativeElement.style.setProperty('opacity', `${1 - this.infoOpacity}`);

        this.cdr.detectChanges();
    }

    getParallax() {
        const modalContent = document.querySelector('.modal-content') as HTMLElement;
        this.isIosParallax = this.supportCSSStyle('-webkit-overflow-scrolling', modalContent);
    }

    supportCSSStyle(prop: string, modalContent: HTMLElement) {
        return prop in modalContent.style;
    }

    getBackgroundImageCss(imageUrl: string) {
        return this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }

    onClickButtonClose() {
        this.close();
    }
}
