import {ApplicationRef, Injectable} from '@angular/core';
import {
    AlertController,
    LoadingController,
    MenuController,
    ModalController,
    NavController,
    Platform
} from '@ionic/angular';
// import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Facebook} from '@ionic-native/facebook/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {popupEnterAnimation, popupLeaveAnimation} from '../core/animations/popup.animations';
import {NetworkInterface} from '@ionic-native/network-interface/ngx';
import {SocialSharing} from '@ionic-native/social-sharing/ngx';
import {PickerController} from '@ionic/angular';
import {PickerOptions, PickerColumnOption} from '@ionic/core';
import {ProductOffer} from '../entity/productOffer.entity';
import {BehaviorSubject, Subject} from 'rxjs';
import {AnalyticsService} from './analytics.service';
import {ModalService} from './modal.service';

import {Chooser, ChooserResult} from '@ionic-native/chooser/ngx';
import {FilePath} from '@ionic-native/file-path/ngx';
import {AndroidPermissions} from '@ionic-native/android-permissions/ngx';
import {ActionSheetController} from '@ionic/angular';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';
import {FirebasePlugin} from 'cordova-plugin-firebasex';
import * as StackTrace from 'stacktrace-js';
import {environment} from '../../environments/environment';

function _window(): any {
    return window;
}

@Injectable({
    providedIn: 'root',
})
export class AppService {
    appReadySubject = new BehaviorSubject(false);

    get appReady(): boolean {
        return this.appReadySubject.value;
    }

    set appReady(value) {
        if (value !== this.appReady) {
            console.log('appReady', value);
            this.appReadySubject.next(value);
        }
    }

    menuReadySubject = new BehaviorSubject(false);

    get menuReady(): boolean {
        return this.menuReadySubject.value;
    }

    set menuReady(value) {
        if (value !== this.menuReady) {
            console.log('menuReady', value);
            this.menuReadySubject.next(value);
        }
    }
    menuType: string | 'list' | 'grid';

    hideFooterMenu = false;
    locationFooterMenu = 0;

    rootPage: any = false;
    canGoBack = false;

    platform: Platform;
    nav: NavController;
    menu: MenuController;
    // splashScreen: SplashScreen;
    statusBar: StatusBar;
    facebook: Facebook;
    firebasex: FirebaseX;
    alertController: AlertController;
    appVersion: AppVersion;
    cdr: ApplicationRef;
    modalController: ModalController;
    pickerCtrl: PickerController;
    private socialSharing: SocialSharing;

    ymapsLoaded = false;
    ymapsAPIKey = '';

    phone = '';
    public appVersionNumber: string;
    private modalWindow: any;
    private alertWindow: any;
    private appmetrica: boolean;
    private networkInterface: NetworkInterface;
    deviceId: string;
    windowHeight: number;
    isChangeCity = false;

    private city: string;
    workTimeIsShowed = false;

    public isLoading: boolean;
    public isLoaded = false;
    public loadingCounter = 0;
    loaderController: LoadingController;
    protected loading: any;

    eventCloseProductModal: Subject<any> = new Subject<any>();
    closeModal$ = this.eventCloseProductModal.asObservable();
    productModalIsOpened = false;

    isOpenPickerModal = false;

    eventResetMenuSwiper: Subject<any> = new Subject<any>();
    resetMenuSwiper$ = this.eventResetMenuSwiper.asObservable();


    blockBack = false;

    postError = false;
    httpError = false;
    postErrorList = 0;

    // startPage = true;
    modalService: ModalService;

    chooser: Chooser;
    filePath: FilePath;
    private androidPermissions: AndroidPermissions;
    actionSheetController: ActionSheetController;
    camera: Camera;

    eventChangeMenu: Subject<any> = new Subject<any>();
    changeMenu$ = this.eventChangeMenu.asObservable();
    onForward = true;
    isMenuChanged = false;
    menuOpacity = 1;
    dynamicLinksTestData: any = {};

    changeMenu() {
        this.eventChangeMenu.next();
    }

    constructor(
        platform: Platform,
        menu: MenuController,
        // splashScreen: SplashScreen,
        statusBar: StatusBar,
        facebook: Facebook,
        firebasex: FirebaseX,
        alertController: AlertController,
        modalController: ModalController,
        loaderController: LoadingController,
        cdr: ApplicationRef,
        appVersion: AppVersion,
        networkInterface: NetworkInterface,
        socialSharing: SocialSharing,
        pickerCtrl: PickerController,
        modalService: ModalService,
        chooser: Chooser,
        filePath: FilePath,
        androidPermissions: AndroidPermissions,
        actionSheetController: ActionSheetController,
        camera: Camera,
    ) {

        this.platform = platform;
        this.menu = menu;
        // this.splashScreen = splashScreen;
        this.facebook = facebook;
        this.firebasex = firebasex;
        this.alertController = alertController;
        this.modalController = modalController;
        this.cdr = cdr;
        this.appVersion = appVersion;
        this.statusBar = statusBar;
        this.networkInterface = networkInterface;
        this.socialSharing = socialSharing;
        this.pickerCtrl = pickerCtrl;
        this.loaderController = loaderController;
        this.modalService = modalService;

        const menuType = localStorage.getItem('ru.z2s.app__menuType');
        this.menuType = menuType || 'list';

        this.chooser = chooser;
        this.filePath = filePath;
        this.androidPermissions = androidPermissions;
        this.actionSheetController = actionSheetController;
        this.camera = camera;
    }

    public cleanCdr() {
        this.cdr.tick();
    }

    // @ remove
    public get cityName(): string {
        return this.city;
    }

    public getCityId() {
        return localStorage.getItem('ru.z2s.app__city');
    }

    public set cityName(value: string) {
        this.city = value;
    }

    // getCity() {
    //     let city = localStorage.getItem('ru.z2s.app__city');
    //     if (+city === Cities.Ryazan) {
    //         this.cityName = 'Рязань';
    //     } else {
    //         this.cityName = 'Москва';
    //
    //         if (city === null && city !== 'undefined' && typeof city !== 'undefined') {
    //             city = Cities.Moscow.toString();
    //             localStorage.setItem('ru.z2s.app__city', city);
    //         }
    //     }
    // }

    setStatusBar() {
        this.statusBar.backgroundColorByHexString('#F2F2F2');
        this.statusBar.styleDefault();
    }

    isIos() {
        return this.platform.is('ios');
    }

    isAndroid() {
        return this.platform.is('android');
    }

    isCordova() {
        return this.platform.is('cordova');
    }

    isDevice() {
        return this.isCordova() && (this.isAndroid() || this.isIos());
    }

    getCordova() {
        if (this.isDevice()) {
            return this.getWindow().cordova;
        }

        return null;
    }

    getWindow() {
        return _window();
    }

    // hideSplash() {
    //     this.splashScreen.hide();
    // }
    //
    // showSplash() {
    //     this.splashScreen.show();
    // }

    getScreenHeight() {
        return this.platform.height();
    }

    openMenu() {
        this.menu.open();
    }

    closeMenu() {
        this.menu.close();
    }

    callPhone(phone: string | null = null) {
        this.openLink('tel:' + this.phone);
    }

    openLink(link) {
        if (this.isCordova()) {
            this.getCordova().InAppBrowser.open(link, '_system', 'location=yes');
        } else {
            window.open(link, '_system', 'location=yes');
        }
    }

    public back() {

        if (this.modalService.isModal) {
            this.modalService.close();
            return false;
        }

        if (this.isOpenPickerModal) {
            this.closeModalPicker();
            return false;
        }

        if (this.isLoading) {
            return false;
        }

        if (this.blockBack) {
            return false;
        }

        if (this.alertWindow) {
            this.alertWindow.dismiss();
            return false;
        }

        if (this.modalWindow) {
            this.modalWindow.closeModal();
            return false;
        }

        return true;
    }

    closeProductModal() {
        this.eventCloseProductModal.next();
    }

    public isAuth() {
        const userToken = this.getUserToken();
        return userToken !== 'null' && userToken !== 'undefined' && userToken;
    }

    public showFooter(show = true) {

    }

    public setActiveFooter(index) {
        this.locationFooterMenu = index;
    }

    logError(data: {
        title: string,
        type: string,
        data?: { [key: string]: string },
        error?: any
    }) {
        data.data.prodMode = environment.production ? 'production' : 'debug';
        const firebasePlugin: FirebasePlugin = this.getWindow().FirebasePlugin;
        if (!firebasePlugin) {
            console.warn('FirebasePlugin no initialized into window');
            return;
        }
        // Устанавливаем кастомные ключи ошибки
        if (data.data) {
            for (const key in data.data) {
                if (data.data.hasOwnProperty(key)) {
                    firebasePlugin.setCrashlyticsCustomKey(key, data.data[key]);
                }
            }
        }
        // Устанавливаем тип ошибки в кастомный ключ
        firebasePlugin.setCrashlyticsCustomKey('type', data.type);

        // Получаем стек ошибки, если можем
        StackTrace.fromError(data.error)
            .then((stack) => {
                firebasePlugin.logError(data.title, stack, () => {
                    console.log('%cSend error to firebase 🔥\n' + '%c' + data.error.stack, 'color:#ff6a00', 'color: #838c95');
                });
            })
            .catch((err) => {
                firebasePlugin.logError(data.title, null, () => {
                    console.log('%cSend error to firebase 🔥\n' + '%c' + data.error.message, 'color:#ff6a00', 'color: #838c95');
                });
            });
    }

    initCrashAnalytics() {
        // this.firebasex.setCrashlyticsCollectionEnabled(true);
        // this.firebasex.setAnalyticsCollectionEnabled(true);
    }

    addUserToPush(userId = null) {
        if (userId) {
            this.firebasex.subscribe(userId);
            this.firebasex.setUserId(userId);
        }
    }

    getUserToken() {
        // return '04c26b69b2de27fcb1df8ef685b9db9b';
        // return '14da6dc5f28a0ed52c75752772e1feec';
        // return '998002452a4317528a94a00dbc23f64d';
        return localStorage.getItem('ru.z2s.app__token');
    }

    getGuestToken() {
        return localStorage.getItem('ru.z2s.app__guest');
    }

    setGuestToken(guest) {
        localStorage.setItem('ru.z2s.app__guest', guest);
    }

    removeGuestToken() {
        localStorage.removeItem('ru.z2s.app__guest');
    }

    subscribePush(callback: () => void, callbackPush: (data: any) => void = null) {

        if (this.isIos()) {
            this.firebasex.subscribe('ios');
        }

        if (this.isAndroid()) {
            this.firebasex.subscribe('android');
        }

        this.firebasex.subscribe('all');

        this.firebasex.hasPermission().then((status) => {
            if (!status) {
                this.firebasex.grantPermission().then((e) => {

                });
            }
        });

        this.firebasex.getToken().then(token => {
            this.deviceId = token;

            callback();
        });


        this.firebasex.onMessageReceived().subscribe(data => {
            if (typeof data.show !== 'undefined') {
                if (data.show === '1') {
                    let msg = data.body;

                    if (typeof data.text !== 'undefined') {
                        msg = data.text;
                    }

                    this.showMessage(data.title, msg);
                }
            }

            if (callbackPush) {
                callbackPush(data);
            }

        });
    }

    public unSubscribePush(userId = null) {
        this.firebasex.clearAllNotifications();

        if (this.isIos()) {
            this.firebasex.unsubscribe('ios');
        }

        if (this.isAndroid()) {
            this.firebasex.unsubscribe('android');
        }

        if (userId) {
            this.firebasex.unsubscribe(userId);
        }
    }

    public gaPageView() {

        if (this.isDevice()) {
            // const p = this.router.url.replace('/', '');
            //
            // this.firebasex.setScreenName(p);
            // this.sendEvent('page_view', {page: p});
        }

    }

    getVersion(callback: () => void) {

        if (this.isCordova()) {

            this.appVersion.getVersionNumber().then((ver) => {
                this.appVersionNumber = ver;
                callback();
            });

        } else {
            this.appVersionNumber = 'browser';
            callback();
        }

    }

    initAppMetrika() {
        if (this.isCordova() && typeof this.getWindow().appMetrica !== 'undefined') {
            AnalyticsService.initAppMetrica(this.appVersionNumber);
        }
    }

    closeModalPicker() {
        if (this.modalWindow) {
            this.modalController.dismiss({action: 'close'});
        }
    }

    async openModal(component, props = null, callback: (data: any) => void = null, swipeToClose: boolean = true) {
        this.modalWindow = await this.modalController.create({
            component,
            componentProps: props,
            swipeToClose,
            enterAnimation: popupEnterAnimation,
            leaveAnimation: popupLeaveAnimation,
            cssClass: 'app-modal',
        });

        await this.modalWindow.present().then(() => {
            this.isOpenPickerModal = true;

        });

        await this.modalWindow.onDidDismiss().then((res) => {

            if (res.data && callback) {
                callback(res.data);
            }

            this.isOpenPickerModal = false;
            this.modalWindow = null;
        });

        return this.modalWindow;
    }

    public async showMessage(title, message = null, callback: () => void = null) {
        if (this.alertWindow) {
            await this.alertController.dismiss();
        }
        this.alertWindow = await this.alertController.create({
            mode: this.isIos() ? 'ios' : 'md',
            header: title,
            message,
            buttons: ['Ok']
        });

        await this.alertWindow.present();

        await this.alertWindow.onDidDismiss().then((res) => {

            if (callback) {
                callback();
            }

            this.alertWindow = null;
        });
    }

    async showLoading() {
        this.loadingCounter++;

        // if (!this.isLoading && !this.startPage) {
        if (!this.isLoading) {

            this.isLoading = true;

            this.loading = await this.loaderController.create({
                message: '<i class="fa fa-spinner fa-spin" aria-hidden="true"></i>',
                spinner: null,
                cssClass: 'spinner',
                showBackdrop: false
            });
            await this.loading.present();
        }
    }

    async hideLoading(all = false) {
        this.loadingCounter--;

        if (all) {
            this.loadingCounter = 0;
        }

        if (this.loadingCounter <= 0) {

            if (this.loading) {
                this.loading.dismiss();
                this.loading = null;
            }

            this.loadingCounter = 0;

            // if (this.isLoading) {
            this.isLoading = false;
            this.isLoaded = true;
            return await this.loaderController.dismiss(null, 'cancel');
            // }


        }

        return null;

    }


    async openPicker(columnOptions: PickerColumnOption[], selectedIndex: number = 0, classPicker: string, callback: (selectedSideDish: ProductOffer & { selectedIndex: number }) => void) {
        const options: PickerOptions = {
            buttons: [
                {
                    text: 'Отмена',
                    role: 'cancel'
                },
                {
                    text: 'Готово'
                }
            ],
            columns: [{
                name: 'sideDish',
                options: columnOptions
            }],
            mode: 'ios',
            cssClass: classPicker
        };

        const picker = await this.pickerCtrl.create(options);
        picker.columns[0].selectedIndex = selectedIndex;
        picker.present();

        picker.onDidDismiss().then(async response => {
            if (response.data) {
                const selectedSideDish: (ProductOffer & { selectedIndex: number }) = response.data.sideDish.value;
                const col = await picker.getColumn('sideDish');
                const selectedIndexTemp = col.selectedIndex;

                selectedSideDish.selectedIndex = selectedIndexTemp;
                callback(selectedSideDish);
            }
        });
    }

    getSassVar(varName) {
        const appStyles = window.getComputedStyle(document.getElementsByTagName('ion-app')[0]);
        return parseInt(appStyles.getPropertyValue(varName), 0);
    }

    getIpAddress(callback: (ipAddress) => void) {
        this.networkInterface.getWiFiIPAddress()
            .then(address => {
                callback(address.ip);
            })
            .catch(error => {
                this.networkInterface.getCarrierIPAddress()
                    .then(address => {
                        callback(address.ip);
                    })
                    .catch(err => {
                        callback('127.0.0.1');
                    });
            });
    }

    shareApp() {
        let link;
        if (this.isIos()) {
            link = 'https://apps.apple.com/ru/app/hatimaki/id1325997612';
        } else {
            link = 'https://play.google.com/store/apps/details?id=com.ionicframework.hatimaki&hl=ru';
        }
        const options = {
            message: link
        };
        this.socialSharing.shareWithOptions(options);
    }

    resetMenuMainSwiper() {
        this.eventResetMenuSwiper.next();
    }

    public async getFileFromDevice(accept, callback: (fileObj: ChooserResult, filePath: string) => void) {
        const startChoseFile = () => {
            this.chooser.getFile(accept).then((value: ChooserResult) => {
                if (this.isAndroid() && this.filePath && value.uri) {
                    this.filePath.resolveNativePath(value.uri).then(nativepath => {
                        callback(value, nativepath);
                    }, (err) => {
                        console.log('err', err);
                        callback(value, value.uri);
                    });
                } else {
                    callback(value, value.uri);
                }

            }, err => {
                console.log('err', err);
            });
        };
        if (this.isAndroid()) {
            this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE).then(
                result => {
                    if (result.hasPermission) {
                        startChoseFile();
                    } else {
                        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
                            .then(data => {
                                    if (data.hasPermission) {
                                        startChoseFile();
                                    }
                                }
                            );
                    }
                },
                err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
                    .then(result => {
                            if (result.hasPermission) {
                                startChoseFile();
                            } else {
                                this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_EXTERNAL_STORAGE)
                                    .then(data => {
                                            if (data.hasPermission) {
                                                startChoseFile();
                                            }
                                        }
                                    );
                            }
                        }
                    )
            );
        } else {
            startChoseFile();
        }
    }

    fixStatusbarAfterReenter() {
        this.statusBar.overlaysWebView(true);
        this.statusBar.overlaysWebView(false);
        this.setStatusBar();
    }

    public async selectPhoto(callback: (imageData: string) => void) {
        const actionSheet = await this.actionSheetController.create({
            mode: this.isAndroid() ? 'md' : 'ios',
            buttons: [
                {
                    text: 'Выбрать фото',
                    handler: () => {
                        this.makePhoto(this.camera.PictureSourceType.PHOTOLIBRARY, callback);
                    }
                },
                {
                    text: 'Сделать фото',
                    handler: () => {
                        this.makePhoto(this.camera.PictureSourceType.CAMERA, callback);
                    }
                },
                {
                    text: 'Отмена',
                    role: 'cancel',
                    handler: () => {
                    }
                }
            ]
        });
        await actionSheet.present();
    }

    public makePhoto(type, callback: (imageData: string) => void) {
        if (this.isCordova()) {


            const options: CameraOptions = {
                quality: 100,
                destinationType: this.camera.DestinationType.DATA_URL,
                encodingType: this.camera.EncodingType.JPEG,
                mediaType: this.camera.MediaType.PICTURE,
                correctOrientation: true,
                cameraDirection: this.camera.Direction.FRONT,
                sourceType: type
            };

            // console.log(options);

            this.camera.getPicture(options).then((imageData) => {

                callback(imageData);

            }, (err) => {
                // Handle error
            });
        }
    }

}
