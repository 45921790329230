import {NgModule} from '@angular/core';
import {SanitizePipe} from './sanitize.pipe';
import {AddressInlinePipe} from './address.pipe';
import {MonthPipe} from './month.pipe';


@NgModule({

    declarations: [
        SanitizePipe,
        AddressInlinePipe,
        MonthPipe
    ],
    exports: [
        SanitizePipe,
        AddressInlinePipe,
        MonthPipe
    ]
})
export class PipesModule {
}
