import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Address} from '../../../entity/address.entity';
import {AddressService} from '../../../models/address.service';
import {UserService} from '../../../models/user.service';

@Component({
    selector: 'app-popup-save-address',
    templateUrl: './popup-save-address.component.html',
    styleUrls: ['./popup-save-address.component.scss'],
})
export class PopupSaveAddressComponent implements OnInit {
    @Input() address: Address;
    private modalController: ModalController;
    private addressService: AddressService;
    private userService: UserService;
    errorMessage = '';


    constructor(
        modalController: ModalController,
        addressService: AddressService,
        userService: UserService,
    ) {
        this.modalController = modalController;
        this.addressService = addressService;
        this.userService = userService;
    }

    ngOnInit() {
        this.address.id = 0;
        this.address.name = '';
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    saveTemplate() {
        if (this.address.name === '') {
            this.errorMessage = 'Поле обязательно для заполнения';
            return;
        }

        this.addressService.updateAddress(this.address, (data) => {
            this.modalController.dismiss({action: 'saved', status: data});
        }, true);
    }

    onCancel() {
        this.modalController.dismiss({action: 'canceled'});
    }

}
