import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
    selector: 'time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {

    // value
    @Output() valueChange = new EventEmitter<string>(true);

    @Input() value;

    // timeStart - start time from which time selection is possible in 'hh:mm'
    @Input() timeStart: string;
    // timeStart - end time from until time selection is possible in 'hh:mm'
    @Input() timeEnd: string;
    // timeStart - the time delay in minutes is added to the timeStart
    @Input() timeDelay = '';

    interval = 30;

    hoursList = [];
    hoursListFull = [];
    minutesList = [];
    minutesListFull = [];
    hIndex = 0;
    hIndexN = 0;
    mIndex = 0;
    mIndexN = 0;
    hIsDrag = false;
    mIsDrag = false;
    oldY = 0; // mouser coords on start drag
    hY = 0; // coords on start drag
    hDY = 0; // increment coords before end drag
    mY = 0; // coords on start drag
    mDY = 0; // increment coords before end drag
    private startHours: number;
    private startMinutes: number;
    private endHours: number;
    private endMinutes: number;
    private delay: number;
    private SliderConfig: SwiperConfigInterface = {
        direction: 'vertical',
        slidesPerView: 'auto',
        centeredSlides: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeSticky: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumRatio: 0.2,
        observer: true,
    };
    private hSlider;
    private mSlider;
    private minutesListArray: any[];
    private isHoursSliderDrag = false;

    constructor() {
    }

    ngOnInit() {
        const regexTime = /([0-1][0-9]|[2][0-3]):[0-5][0-9]/;
        if (!regexTime.test(this.timeStart)) {
            console.warn('TimePicker: input parameter timeStart parameter is not in the correct format');
            return;
        }
        if (!regexTime.test(this.timeEnd)) {
            console.warn('TimePicker: input parameter timeEnd parameter is not in the correct format');
            return;
        }
        if (isNaN(Number(this.timeDelay))) {
            console.warn('TimePicker: input parameter timeDelay parameter is not in the correct format');
            return;
        }

        this.startHours = Number(this.timeStart.slice(this.timeStart[0] === '0' ? 1 : 0, 2));
        this.startMinutes = Number(this.timeStart.slice(this.timeStart[3] === '0' ? 4 : 3, 5));
        this.endHours = Number(this.timeEnd.slice(this.timeEnd[0] === '0' ? 1 : 0, 2));
        this.endMinutes = Number(this.timeEnd.slice(this.timeEnd[3] === '0' ? 4 : 3, 5));
        this.delay = Number(this.timeDelay);

        this.startHours += Math.floor((this.startMinutes + this.delay) / 60);  // 16
        this.startMinutes = (this.startMinutes + this.delay) % 60;  // 57


        if (
            this.startHours * 60 + this.startMinutes > this.endHours * 60 + this.endMinutes
        ) {
            console.warn('TimePicker:  timeStart + delay cannot exceed timeEnd');
            return;
        }


        this.hoursList = this.getArray(1, this.startHours, this.endHours);

        this.prepareMinutesList();
        if (this.value) {
            const findH = this.hoursList.findIndex(item => item === this.value.slice(0, 2));
            if (findH > -1) {
                this.setHour(findH);
            }
            this.updateMinutesList();
            const findM = this.minutesList.findIndex(item => item === this.value.slice(3, 5));
            if (findM > -1) {
                this.setMinutes(findM);
            }
        } else {
            this.updateMinutesList();
        }
    }

    prepareMinutesList() {
        this.minutesListArray = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.hoursList.length; i++) {
            let list = [];
            if (this.hoursList[i] === this.startHours.toString()) {
                let start = Math.floor(this.startMinutes / this.interval);
                start = this.startMinutes % this.interval ? start + 1 : start;
                list = this.getArray(this.interval, start * this.interval, 60 - this.interval);
            } else if (this.hoursList[i] === this.endHours.toString()) {
                const end = Math.floor(this.endMinutes / this.interval);
                list = this.getArray(this.interval, 0, end * this.interval);
            } else {
                list = this.getArray(this.interval, 0, 60 - this.interval);
            }
            this.minutesListArray.push(list);
        }
        for (let i = 0; i < this.minutesListArray.length; i++) {
            if (!this.minutesListArray[i].length) {
                this.hoursList.splice(i, 1);
                this.minutesListArray.splice(i, 1);
            }
        }
    }

    getArray(step: number, start: number, end: number): string[] {
        const list = [];
        for (let i = start; i <= end; i += step) {
            list.push(i >= 10 ? i.toString() : '0' + i.toString());
        }
        return list;
    }

    updateMinutesList() {
        const oldNumber = this.minutesList[this.mIndex];

        this.minutesList = this.minutesListArray[this.hIndex];

        const restoreIndex = this.minutesList.findIndex(item => item === oldNumber);
        if (this.mSlider) {
            this.mSlider.directiveRef.update();
        }
        this.setMinutes(restoreIndex > -1 ? restoreIndex : 0);
    }

    setHour(index: number) {
        if (index >= 0 && index <= this.hoursList.length - 1) {
            this.hIndex = index;
            if (this.hSlider) {
                this.hSlider.directiveRef.swiper().slideTo(this.hIndex);
                this.hSlider.directiveRef.update();
            }

            this.updateMinutesList();
        }
    }

    setMinutes(index: number) {
        if (index >= 0 && index <= this.minutesList.length - 1) {
            if (this.mSlider) {
                this.mIndex = index;
                this.mSlider.directiveRef.swiper().slideTo(index);
                this.mSlider.directiveRef.update();
            }
        }
        this.valueChange.emit(this.hoursList[this.hIndex] + ':' + this.minutesList[this.mIndex]);
    }

    hSliderInit(slider) {
        this.hSlider = slider;
        this.hSlider.config = this.SliderConfig;
        this.hSlider.directiveRef.swiper().slideTo(this.hIndex, 0);
        this.hSlider.directiveRef.update();
    }

    mSliderInit(slider) {
        this.mSlider = slider;
        this.mSlider.config = this.SliderConfig;
        this.mSlider.directiveRef.swiper().slideTo(this.mIndex, 0);
        this.mSlider.directiveRef.update();
    }

    hSliderChange(index) {
        this.hIndex = index;
    }

    hSwiperTransitionEnd() {
        this.updateMinutesList();
        this.mSlider.directiveRef.update();
    }

    mSliderTransitionEnd(index) {
        this.setMinutes(index);
    }

}
