import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Wok} from '../entity/wok.entity';
import {ApiResponse} from '../entity/apiResponse.entity';

@Injectable({
    providedIn: 'root',
})

export class WokService extends MainService {

    public wok: Wok;
    public wokConstructor = [];

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);

        this.newWokConstructor();
    }

    public newWokConstructor() {
        this.wok = new Wok();
    }

    public getWok(callback: (status: boolean) => void) {
        if (Object.keys(this.wok).length === 0) {
            this.getRequest({}, (wok: ApiResponse) => {
                this.wok = wok.result;
                this.getWokConstructor();
                callback(true);
            }, 'wok.php');
        }
    }

    private getWokConstructor() {
        this.wokConstructor = this.wok.steps.map(step => {

            return step.items = step.items.map(item => {
                item.checked = false;
                item.disabled = false;
                return item;
            });
        });

        this.wok.without[0].checked = false;
        this.wok.without[0].disabled = false;

        this.wokConstructor.push(this.wok.without);
    }
}
