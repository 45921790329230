import {Injectable} from '@angular/core';
import {IonRouterOutlet, NavController} from '@ionic/angular';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import {AppService} from './app.service';
import {UserService} from '../models/user.service';
import {CartService} from '../models/cart.service';
import {filter} from 'rxjs/operators';
import {logger} from "codelyzer/util/logger";

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    nav: NavController;
    router: Router;
    appService: AppService;
    userService: UserService;
    storedPath: string;
    cartService: CartService;

    public routerOutlet: IonRouterOutlet;

    public previousUrl;
    public currentUrl;
    public urlBeforeCart: string;

    // public stack: StackController;

    constructor(
        nav: NavController,
        // stack: StackController,
        router: Router,
        userService: UserService,
        routes: RouterModule,
        cartService: CartService,
        appService: AppService
    ) {
        this.nav = nav;
        // this.stack = stack;
        this.router = router;
        this.appService = appService;
        this.cartService = cartService;
        this.userService = userService;

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            if (event.url.includes('cart')) {
                this.urlBeforeCart = this.currentUrl;
            }
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
        });
    }

    public goToPage(page: any, root: boolean = false, data: any = null) {
        const extras = {
            replaceUrl: false,
            queryParams: null,
        };

        if (root) {
            extras.replaceUrl = true;
        }

        if (data) {
            extras.queryParams = data;
        }

        if (this.authRequired(page) && !this.userService.isLogin()) {
            this.storedPath = page;
            page = 'authorization';
        }
        if (this.authRequired(page) && this.userService.isLogin() && !this.userService.user?.defaultAddress) {
            this.storedPath = page;
            page = 'default_address';
        }
        this.appService.closeMenu();
        this.nav.navigateForward('/' + page, extras);
    }

    public removeFromStack() {
        this.routerOutlet.getLastUrl();
    }

    public getRoutes() {
        return this.router.config;
    }

    restorePath(alternativePage: string, root: boolean = false, data: any = null) {
        const token = localStorage.getItem('ru.z2s.app__token');

        if (this.storedPath && token) {

            if (this.storedPath.includes('ordering')) {

                if (this.cartService.cart?.products?.length) {
                    this.goToPage(this.storedPath, root, data);
                } else {
                    this.goToPage(alternativePage, root, data);
                }

            } else {
                this.goToPage(this.storedPath, root, data);
            }

            this.storedPath = null;
        } else {
            this.goToPage(alternativePage, root, data);
        }
    }

    getRoutesForMenu() {
        const routes = this.getRoutes().filter(item => {
            if (item.path !== 'authorization' && this.userService.isLogin()) {
                return item.data?.menu;
            } else if (item.path !== 'profile/main' && !this.userService.isLogin()) {
                return item.data?.menu;
            }
        });

        return routes;
    }

    private getRouteByPath(path) {
        return this.getRoutes().find((item) => {
            return item.path.search(path) !== -1;
        });
    }

    private authRequired(path) {
        return this.getRouteByPath(path)?.data?.auth;
    }

    public back(fullBack = false) {

        if (this.appService.back() && this.canGoBack()) {

            if (fullBack === false && this.canGoBack()) {
                this.routerOutlet.pop(1);
            } else if (fullBack) {

                for (let i = 1; i <= 10; i++) {
                    if (this.canGoBack(i) === false) {
                        this.routerOutlet.pop(i - 1);
                    }
                }

            }

        }
    }

    canGoBack(index = 1) {
        return this.routerOutlet?.canGoBack(index);
    }


    routeActive(path: string) {
        return this.router.isActive(path, false);
    }
}
