import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {ApiResponse} from '../entity/apiResponse.entity';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SuggestionsService extends MainService {

    baseUrl;
    searchMobileUrl = 'https://www.hatimaki.ru/include/search_mobile.php';

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    getSuggestions(term, callback: (result: ApiResponse) => void, isLoading = true) {

        this.getRequest({
            term,
        }, (response: ApiResponse) => {
            callback(response);
        }, 'location/search', true, false);
    }
}
