import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Product} from '../entity/product.entity';
import {ApiResponse} from '../entity/apiResponse.entity';

@Injectable({
    providedIn: 'root',
})

export class RecommendProductService extends MainService {

    recommendCache = {};

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    public getRecommended(productId, callback: (data: { title: string, products: Product[] }) => void) {
        if (this.recommendCache[productId]) {
            callback(this.recommendCache[productId]);
        } else {
            this._getRecommendedRequest(productId, (data) => {
                this.recommendCache[productId] = data.status && data.result ? data.result : [];
                callback(this.recommendCache[productId]);
            });
        }
    }

    private _getRecommendedRequest(productId: number, callback: (result: ApiResponse<{ title: string, products: Product[] }>) => void) {
        this.postRequest({product: productId}, response => {
            callback(response);
        }, 'catalog/recommended', false, false);
    }
}
