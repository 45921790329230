import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {MainItem} from '../../core/main.item';
import {DeliveryType} from '../../entity/cart.entity';
import {DefaultAddress} from '../../entity/profile.entity';
import {AppService} from '../../services/app.service';
import {BranchService} from '../../models/branch.service';
import {Branch} from '../../entity/restaurant.entity';
import {SuggestionsService} from '../../models/suggestions.service';
import {UserService} from '../../models/user.service';
import {DeliveryService} from '../../models/delivery.service';
import {NavigationService} from '../../services/navigation.service';

@Component({
    selector: 'default-address-form',
    styleUrls: ['./default-address-form.scss'],
    templateUrl: 'default-address-form.html',
})

export class DefaultAddressFormComponent extends MainItem implements OnDestroy, OnInit, AfterViewInit {

    public DeliveryType = DeliveryType;
    public defaultAddress: DefaultAddress;
    public addressConfirm = false;
    public addressSuggestions: any[] = [];
    public pageReady = true;
    public touchTarget: any;
    private citySearchTimer;
    public maxLengthInputField = 10;
    public deliveryMessage = '';

    public isOpenAddressList = false;
    public isOpenPickUpList = false;
    public isCheckedStreet = false;
    public isCheckedAddress = false;
    public isDeliveryAddress = false;

    @Output() saveDefaultAddressEvent: EventEmitter<'address' | 'catalog'> = new EventEmitter();

    constructor(
        public appService: AppService,
        public branchService: BranchService,
        public suggestionsService: SuggestionsService,
        public userService: UserService,
        public deliveryService: DeliveryService,
        public navigationService: NavigationService,
        public cdr: ChangeDetectorRef,
    ) {
        super();
    }

    ionViewWillEnter() {
        if (this.defaultAddress.courier?.street) {
            this.isCheckedStreet = true;
        }
    }

    ngOnInit() {

        // создаем локальную копию деф адреса
        this.defaultAddress = new DefaultAddress();
        if (this.userService.user?.defaultAddress) {
            this.defaultAddress.type = this.userService.user.defaultAddress.type;
            this.defaultAddress.courier = {...this.userService.user.defaultAddress.courier};
            this.defaultAddress.pickup = {...this.userService.user.defaultAddress.pickup};
            this.defaultAddress.courierPoint = {...this.userService.user.defaultAddress.courierPoint};
        }

        // открываем нужный таб
        this.changeTab(this.defaultAddress.type);

        this.isCheckedStreet = !!this.defaultAddress.courier?.street;

        if (this.defaultAddress.courier?.street) {
            this.checkAddress();
        } else {
            this.isCheckedAddress = false;
        }

        // получаем список филиалов
        this.branchService.getData(res => {
            if (res) {
                // если получили толькой 1 филиал и он не выбран еще, то выбераем его
                if (this.defaultAddress.pickup === null && this.branchService.data.length === 1) {
                    this.selectBranch(this.branchService.data[0]);
                }
                this.cdr.detectChanges();
            }
        }, true);

        this.isDeliveryAddress = this.defaultAddress.pickup !== null &&
            this.defaultAddress.pickup?.length !== 0;
    }

    ngOnDestroy() {
    }

    ngAfterViewInit() {
    }

    changeTab(index: DeliveryType) {
        this.defaultAddress.type = index;
    }

    trackByFn(index, item) {
        return item.id;
    }

    getSuggestions() {
        this.isCheckedAddress = false;
        if (this.defaultAddress.courier?.street?.length > 1) {
            clearTimeout(this.citySearchTimer);
            this.citySearchTimer = setTimeout(() => {
                this.suggestionsService.getSuggestions(this.defaultAddress.courier?.street, (data) => {
                    this.addressSuggestions = data.result;
                    this.isOpenAddressList = this.addressSuggestions?.length > 0;
                    // this.cdr.detectChanges();
                }, false);
                clearTimeout(this.citySearchTimer);
            }, 500);
        } else {
            this.addressSuggestions = [];
            this.isOpenAddressList = false;
        }
    }

    checkStreet(evt: Event) {
        const street = (evt.target as HTMLInputElement).value.trim();
        if (!street) {
            this.isCheckedStreet = false;
            this.defaultAddress.courier.house = '';
            this.clearAddress();
        } else {
            this.isCheckedStreet = true;
        }

        this.addressConfirm = false;

        setTimeout(() => {
            this.checkAddress();
        }, 10);

    }

    selectAddress(address: string, event?: any) {
        if (event) {
            event.stopPropagation();
        }
        this.defaultAddress.courier.street = address;
        this.isOpenAddressList = false;
    }

    checkAddress() {
        this.addressConfirm = false;

        if (this.defaultAddress.courier.street && this.defaultAddress.courier.house) {
            const value = this.defaultAddress.courier.house ?
                this.defaultAddress.courier.street + ', д' + this.defaultAddress.courier.house :
                this.defaultAddress.courier.street;
            this.deliveryMessage = '';
            this.deliveryService.checkAddress(
                value,
                (data) => {
                    this.defaultAddress.courierPoint = data.result;
                    this.addressConfirm = data.status;
                    this.deliveryMessage = !data.status && data.message ? data.message : '';
                    this.isCheckedAddress = true;
                    if (!this.addressConfirm) {
                        this.clearAddress();
                    }
                });
        } else {
            this.isCheckedAddress = false;
            this.clearAddress();
            this.addressConfirm = false;
        }
    }

    selectBranch(branch: Branch) {
        this.appService.facebook.logEvent('address_cart');

        this.isOpenPickUpList = false;
        this.defaultAddress.pickup = branch;
        this.isDeliveryAddress = true;
    }

    @HostListener('document:touchend', ['$event.target'])
    touchend(target) {
        let needUpdate = false;
        this.touchTarget = target;

        if ((this.isOpenAddressList || !this.defaultAddress.courier?.street) && !target.closest('.input_address')) {
            if (!this.isCheckedAddress) {
                this.clearAddress(true);
            }
            this.isOpenAddressList = false;
            needUpdate = true;
        }

        if (this.isOpenPickUpList && !target.closest('.input_branch_pickup')) {
            this.isOpenPickUpList = false;
            needUpdate = true;
        }

        if (needUpdate) {
            this.cdr.detectChanges();
        }
    }

    private clearAddress(all?: boolean) {
        if (all) {
            this.addressConfirm = false;
            this.isCheckedStreet = false;
            this.defaultAddress.courier.street = '';
            this.defaultAddress.courier.house = '';
        }
        this.defaultAddress.courier.building = '';
        this.defaultAddress.courier.porch = '';
        this.defaultAddress.courier.intercom = '';
        this.defaultAddress.courier.floor = '';
        this.defaultAddress.courier.office = '';
        this.addressSuggestions = [];
    }

    saveDefaultAddress() {
        this.userService.saveDefaultAddress(this.defaultAddress, (type) => {
            this.saveDefaultAddressEvent.emit(type);
        });
    }

    isAvailableFoSave() {
        switch (this.defaultAddress.type) {
            case DeliveryType.Courier:
                return this.addressConfirm && this.defaultAddress.courier?.house;
            case DeliveryType.Pickup:
                return this.defaultAddress.pickup?.id;
        }
    }

    clearStreet() {
        this.defaultAddress.courier.street = '';
    }
}
