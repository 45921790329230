import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    Output,
    EventEmitter
} from '@angular/core';
import {Product} from 'src/app/entity/product.entity';
import {AppService} from 'src/app/services/app.service';
import {MainItem} from '../../core/main.item';
import {CartService} from '../../models/cart.service';
import {ProductOffer} from '../../entity/productOffer.entity';

@Component({
    selector: 'recommend-item',
    styleUrls: ['./recommend-item.scss'],
    templateUrl: 'recommend-item.html'
})

export class RecommendItemComponent extends MainItem implements OnDestroy, OnInit {

    @Input() recommendProduct: Product;
    @Input() offer: ProductOffer = null;
    @Input() syncLoading = false;
    @Input() syncDelay = 0;
    @Output() removeProdFromRecommend = new EventEmitter();

    cartService: CartService;
    appService: AppService;
    cdr: ChangeDetectorRef;

    constructor(
        cartService: CartService,
        cdr: ChangeDetectorRef,
        appService: AppService
    ) {
        super();

        this.cartService = cartService;
        this.appService = appService;
        this.cdr = cdr;
    }

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    addToCart() {
        if (this.offer) {
            this.cartService.addToCart(this.recommendProduct, +1, this.offer.id, null, this.syncDelay, this.syncLoading, null, true);
        } else {
            this.cartService.addToCart(this.recommendProduct, +1, null, null, this.syncDelay, this.syncLoading, null, true);
        }
    }
}
