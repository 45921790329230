import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertController, LoadingController } from '@ionic/angular';
import { MainService } from '../core/main.service';
import { AppService } from '../services/app.service';
import { Order, OrdersDataFromFoquz } from '../entity/order.entity';
import { PopupLoadErrorSurvey } from '../components/popups/popup-loaderror-survey/popup-loaderror-survey.component';



@Injectable({
    providedIn: 'root'
})
export class SurveyService extends MainService {

    baseUrl = 'https://z2s.foquz.ru';
    apiUrl = '';
    surveyToken = 'E02SuvlBk6z-Fn7';

    public ordersWithSurvey: OrdersDataFromFoquz[] = [];
    public completedSurvey: OrdersDataFromFoquz[] = [];
    public notCompletedSurvey: OrdersDataFromFoquz[] = [];
    public ordersStatistic: any;
    public ordersWithSurveyCount = '0';

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    getSurveyData(userPhone: string, link: string, callback: (res: any) => void, isLoading = true) {
        // userPhone = '79852081252';

        const data = {
            'access-token': this.surveyToken,
            phone: userPhone
        };

        this.getRequest(data, callback, link, false, isLoading);
    }

    getSurveyCount(userPhone: string, callback: (status: boolean) => void) {
        const link = `${this.baseUrl}/foquz/api/clients/not-done-answers-count`;
        this.getSurveyData(userPhone, link, res => {
            this.ordersWithSurveyCount = res.count;
            callback(true);
        }, false);
    }

    getOrdersWithSurvey(userPhone: string, callback: (status: boolean) => void) {
        const link = `${this.baseUrl}/foquz/api/clients/answer-with-orders`;
        this.getSurveyData(userPhone, link, res => {
            this.ordersWithSurvey = res.items;
            this.getCompletedSurvey();
            this.getNotCompletedSurvey();
            callback(true);
        }, false);
    }

    getOrderStatistic(userPhone: string, callback: (status: boolean) => void) {
        const link = `${this.baseUrl}/foquz/api/clients/dish-ratings`;
        this.getSurveyData(userPhone, link, res => {
            this.ordersStatistic = res;
            callback(true);
        }, false);
    }

    getCompletedSurvey() {
        this.completedSurvey = this.ordersWithSurvey.filter(order => order.status === 'done');
    }

    getNotCompletedSurvey() {
        this.notCompletedSurvey = this.ordersWithSurvey.filter(order => {
            if (order.status === 'open' || order.status === 'in-progress') {
                return true;
            }
        });
    }

    openSurvey() {

    }
}
