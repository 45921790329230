import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {HelperService} from '../services/helper.service';
import {AppService} from '../services/app.service';
import {Product} from '../entity/product.entity';
import {Category} from '../entity/category.entity';
import {Promotion} from '../entity/promotion.entity';
import {Catalog} from '../entity/catalog.entity';
import {CategoryTypes} from '../entity/lists';
import {ApiResponse} from '../entity/apiResponse.entity';
import {Socials} from '../entity/socials.entity';
import {Version} from '../entity/settings.entity';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CatalogService extends MainService {
    helperService: HelperService;

    chunk: any;
    flipperCount = [];

    mainProductArray: Product[] = [];
    products: Product[][];
    categories: Category[];
    promos: Promotion[];
    birthdayText: string;
    phone: string;
    selectedSideDishes = {};
    socials: Socials = {
        fb: 'https://www.facebook.com/z2s.moskva/',
        inst: 'https://www.instagram.com/z2s.eda/',
        vk: 'https://vk.com/z2s_msk',
        tik_tok: ''
    };
    version: Version;


    eventUpdateStopList: Subject<any> = new Subject<any>();
    updateStopList$ = this.eventUpdateStopList.asObservable();

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService,
        helperService: HelperService
    ) {
        super(http, alert, loader, appService);
        this.helperService = helperService;
    }

    getCatalog(callback: (status: boolean) => void, loading = true) {
        this.appService.isMenuChanged = true;
        this.appService.menuOpacity = 0;
        const getData: any = {token: this.appService.getUserToken()};
        this.getRequest(getData, (data: ApiResponse) => {
            this.prepareCatalog(data.result);
            callback(true);

        }, 'catalog/app', false, loading);
    }

    getCatalogStopList(callback: (status: boolean) => void, token: string = null, loading = false) {
        const getData = token ? {token} : {};
        this.getRequest(getData, (data: ApiResponse) => {
            this.updateStopList(data.result);
            callback(true);
        }, 'catalog/app', false, loading);
    }

    updateStopList(data) {
        // функция обновления офферов товара
        const updateOffers = (source, target) => {
            if (source.offers?.length && target.offers?.length) {
                // проходим по офферам товара в каталоге
                for (const key in source.offers) {
                    if (typeof key !== 'undefined') {
                        // ищем в новом объекте товара такой же оффер
                        const offerIndex = target.offers.findIndex(i => i.id === source.offers[key].id);
                        if (offerIndex > -1) {
                            source.offers[key].isStopList = target.offers[offerIndex].isStopList;
                        }
                    }
                }
            }
        };

        let product: Product;
        for (product of data.products) {
            if (product) {
                let index;

                // Обновляем главный массив товаров
                index = this.mainProductArray.findIndex(item => item.id === product.id);
                if (index > -1) {
                    this.mainProductArray[index].isStopList = product.isStopList;
                    updateOffers(this.mainProductArray[index], product);
                }

                if (this.products) {
                    // Обновляем нулевую категорию (Хит продаж)
                    index = this.products[0]?.findIndex(item => item.id === product.id);
                    if (index > -1) {
                        this.products[0][index].isStopList = product.isStopList;
                        updateOffers(this.products[0][index], product);
                    }
                    // Обновляем категорию текущего товара
                    index = this.products[product.categoryId]?.findIndex(item => item.id === product.id);
                    if (index > -1) {
                        this.products[product.categoryId][index].isStopList = product.isStopList;
                        updateOffers(this.products[product.categoryId][index], product);
                    }
                }
            }
        }
        this.eventUpdateStopList.next();
    }

    public findCategoryById(id) {
        return this.categories?.find((item) => {
            return item.id === id;
        });
    }

    public findByIdProperty(propertyName: string, propertyValue: any) {
        return this.mainProductArray?.filter((item) => {
            return item[propertyName]?.toString() === propertyValue?.toString();
        });
    }

    private prepareCatalog(data: Catalog) {
        const newDishes: Product[][] = [];
        let popular: Product[];
        let versions: Version;
        const catNames = [];

        this.birthdayText = data.settings.birthdayText ? data.settings.birthdayText : null;
        this.phone = data.settings.phone ? data.settings.phone : '';
        this.appService.phone = this.phone;
        this.appService.ymapsAPIKey = data.settings.ymaps_apikey;
        this.helperService.links.confidence = data.settings.privacy_policy;

        versions = data.settings.version;
        this.version = {
            android: versions.android,
            btn: versions.btn,
            critical: versions.critical,
            ios: versions.ios,
            text: versions.text
        };

        for (const cat of data.categories) {
            catNames[cat.id] = cat.name;
        }

        for (const value of data.products) {
            if (typeof newDishes[value.categoryId] === 'undefined') {
                newDishes[value.categoryId] = [];
            }

            value.catName = catNames[value.categoryId];

            // if (value.isProductOfTheDay) {
            //     popular.push(value);
            // }

            // TODO: установить с новым API гарнир по умолчанию
            // if (value.categoryId === 69) {
            //     value.sideDish = [
            //         'с брокколи',
            //         'с булгуром',
            //         'с гречкой и грибами',
            //         'с диким рисом',
            //         'с картофельным пюре',
            //         'с мини картофелем и грибами',
            //         'с овощами гриль',
            //         'с перловкой и маслинами',
            //         'с ризони и брокколи',
            //         'с тушеной капустой тмином'
            //     ];
            //     value.activeSideDish = value.sideDish[0];
            // }

            newDishes[value.categoryId].push(value);
        }

        const popularObject: any = {};

        for (const value of data.products) {
            popularObject[value.id] = value;
            /*if (value.isPopular) {
                popularObject[value.id] = value;
            }*/
        }

        popular = Object.values(popularObject);

        newDishes[0] = popular;
        this.products = newDishes;

        data.categories.unshift({
            id: 0,
            name: 'Все',
            picture: 'assets/img/main/dish4.jpeg',
            defaultImg: null,
            type: CategoryTypes.Single,
        });


        this.mainProductArray = data.products;
        this.categories = data.categories;
        this.promos = data.promotions;

        this.chunk = HelperService.chunk(this.categories.slice(1), 4);
        this.flipperCount = Array(Math.max(this.chunk.length, popular.length));
        this.socials = data.settings.socials;
    }

    loadVersion(callback: (data: any) => void) {
        this.getCatalog(() => {
            callback(this.version);
        });
    }

    clearSelectedSideDish() {
        if (Object.keys(this.selectedSideDishes).length) {
            for (const prop in this.selectedSideDishes) {
                if (this.selectedSideDishes.hasOwnProperty(prop)) {
                    delete this.selectedSideDishes[prop];
                }
            }
        }
    }
}
