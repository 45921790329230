import {Entity} from '../core/main.entity';
import {ProductOffer} from './productOffer.entity';
import {RecommendProduct} from './recommendProduct.entity';
import {WokItem} from './wokItem.entity';


export class Product implements Entity {
    id: number;
    dishId: number | string;
    name: number;
    categoryId: number;
    subCategoryIndex: number | null;
    type: string; // 'basic' | 'pizza' | 'drink' | 'pie'
    offerSelectType: string;  // 'select' | 'radio';
    price: number;
    fprice: number;
    oldPrice: number;
    'preview_text': string;
    isProductOfTheDay: boolean;
    isPopular: boolean;
    isStopList: boolean;
    hasSauces: boolean;
    isSauce: boolean;
    imageUrl: string;
    offers: ProductOffer[];
    offerId: number;
    isWok: boolean;
    imageUrlCard: any;
    weight: any;                        // к значению добавить граммы (например, 300 гр.)
    detail_text: any;
    mainId: number;
    defaultImg: string;
    catName: string;
    quantity = 1;
    category: string;
    offerSize: string;

    isHotForEveryDay: boolean;          // раздел "горячее" для категории "Еда на каждый день"
    sideDish: string[];                 // массив с гарнирами (например, ['с брокколи', 'с булгуром']),
    activeSideDish: string;
    recommendItems: RecommendProduct[];
    nutritionalValue: {
        proteins: string,
        fats: string,
        carbo: string,
        kkal: string
    };
    description: string;               // описание состава Wok-конструктора
    options: WokItem[];
    selectSize: string;
    size: string;
    sizeType: number;                  // 1 - small | 2 - large (3 - middle)
    isCombo: boolean;
    comboProducts: ComboProd[];
    slug: string;
    /*
    * @TODO тут мы будем считать цены товаров
    * */

    public getPrice(qty: number, typePrice = 'price') {

        if (!this.offerId) {
            if (this.options) {
                return this.options.reduce((total, item) => total + +item[typePrice], 0) * qty;
            } else {
                return ((typePrice === 'price') ? this.price : this.oldPrice) * qty;
            }

        } else {
            const offer = this.offers.find(o => {
                return o.id === this.offerId;
            });
            return ((typePrice === 'price') ? offer.price : offer.oldPrice) * qty;
        }
    }

    public getOffer() {
        return this.offers;
    }

    public getIsStopList(offerId: number = null) {
        // return this.isStopList || (offerId != null && this.offers[offerId]?.isStopList);
        // Подправил функцию. Закомменченый изначальный вариант (тот, что выше) не работает.
        return this.isStopList || (offerId != null && this.offers.find(o => o.id === offerId)?.isStopList);
        // Сама функция при вызове ее из product-item.html (во время формирования каталога) не определяется как функция,
        // хотя при вызове например из default-address-form.html, с ней все в порядке

        // TODO ZTOSAPP-267
        // На данный момент, продублировал эту функцию в каждый компонент, откуда она вызывается без проблем
        // При рефакторинге хорошо бы разобратсья, почему вызов функции, описанной здесь, вызывает проблемы.
    }
}

export class ComboProd {
    defaultImg: string;
    fprice: number;
    id: number;
    imageUrl: string;
    imageUrlCard: string[];
    name: string;
    oldPrice: number;
    price: number;
}
