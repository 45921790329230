import {
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, retryWhen, delay, mergeMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PopupErrorComponent} from 'src/app/components/popups/popup-error/popup-error.component';
import {AppService} from 'src/app/services/app.service';
import {ModalController} from '@ionic/angular';
import {UserService} from '../../models/user.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    ignoreHostsList = ['z2s.foquz.ru'];
    trackError = 'order/status';
    retryes = 1;
    delayTime = 3000;

    constructor(
        private appService: AppService,
        private userService: UserService,
        private modalController: ModalController,
    ) {
    }

    public async wait(condFunc) {
        return new Promise((resolve) => {
            if (condFunc()) {
                resolve();
            } else {
                setTimeout(async () => {
                    await this.wait(condFunc);
                    resolve();
                }, 500);
            }
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retryWhen((errors: Observable<any>) => errors.pipe(
                delay(this.delayTime),
                mergeMap(async (error) => {

                    // alert(navigator.onLine);
                    if (!navigator.onLine) {
                        this.appService.hideLoading(true);

                        if (this.retryes-- > 0) {
                            this.retryes++;

                            if (!this.appService.postError) {
                                if (error.url && error.url.toString().includes(this.trackError) && this.appService.postErrorList < 3) {
                                    this.appService.postErrorList++;
                                } else {
                                    this.openInternetErrorModal();
                                    // this.appService.hideLoading();
                                }
                            }

                            await this.wait(() => this.appService.httpError === true);

                            this.modalController.dismiss({action: 'close'});
                            // this.appService.showLoading();

                            return of(error).pipe(
                                delay(this.delayTime)
                            );
                        }
                    } else {
                        // console.log('Try retry online');
                        // this.retryes = 0;
                        // return of(error).pipe(
                        //     delay(this.delayTime)
                        // );

                        // throwError(error);
                        this.appService.hideLoading();
                        throw(error);
                    }
                    this.retryes = 1;
                })
            )),
            catchError((error: HttpErrorResponse) => {

                // alert(navigator.onLine);

                const url = new URL(error?.url);

                if (url && url.toString().includes(this.trackError) && this.appService.postErrorList < 3) {
                    this.appService.postErrorList++;
                    return throwError(error);
                }
                if (url && this.ignoreHostsList.includes(url.host)) {
                    return throwError(error);
                }
                const data = {
                    userId: this.userService.user.userId,
                    userToken: this.userService.user.id,
                    userPhone: this.userService.user.phone,
                    city: this.appService.cityName,
                    request: JSON.stringify(request),
                    requestUrl: request.url,
                    error: JSON.stringify(error)
                };
                const err = new Error('HttpErrorInterceptor');
                err.stack = 'Error: HttpErrorInterceptor\n    at intercept.catchError (http-error.interceptor.ts:111:1)';
                this.appService.logError({
                    type: 'HttpErrorInterceptor.handleError',
                    title: error.message,
                    data,
                    error: err
                });

                // Защита от повторного запуска модалки
                if (!this.appService.postError) {
                    if (navigator.onLine) {
                        this.openErrorModal();
                    } else {
                        this.openInternetErrorModal();
                    }
                }

                return throwError(error);
            })
        ) as Observable<HttpEvent<any>>;
    }

    openErrorModal() {
        // this.appService.hideSplash();
        this.appService.openModal(PopupErrorComponent, {
            text: 'Мы уже работаем над её устранением. Вы можете сделать заказ на сайте или по телефону',
            link: 'https://www.z2s.ru',
            phone: this.appService.phone,
        }, (data) => {

        });
    }

    openInternetErrorModal() {
        this.appService.hideLoading(true);
        // this.appService.hideSplash();

        this.appService.httpError = false;
        this.appService.openModal(PopupErrorComponent, {
            text: 'Отсутствует интернет соединение',
            internetError: true,
        }, (data) => {

        });
    }
}
