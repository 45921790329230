import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-popup-loaderror-survey',
    templateUrl: './popup-loaderror-survey.component.html',
    styleUrls: ['./popup-loaderror-survey.component.scss'],
})
export class PopupLoadErrorSurvey implements OnInit {
    private modalController: ModalController;
    @Input() text = '';
    @Input() button = 'Понятно';

    constructor(
        modalController: ModalController
    ) {
        this.modalController = modalController;
    }

    ngOnInit() {
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

}
