import {Entity} from '../core/main.entity';

export class Branch implements Entity {
    id: number;
    close: string;
    cookingTime: string;
    coord_lat: string;
    coord_lon: string;
    name: string;
    terms: string;
    address: string;
    guid: string;
    metro: string;
    open: string;
    phone: string;
    street: string;
    mobileCards: string;
    detailText: string;
    accountNumber: string;
    termsShort: string;
    notPickup: boolean;
    description: string;
    images: string;
    length?: number;
    timeOptions: {
        start: string,
        end: string,
        delay: string
    };
    slug: string;
}
