import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-popup-birthday',
    templateUrl: './popup-birthday.component.html',
    styleUrls: ['./popup-birthday.component.scss'],
})
export class PopupBirthdayComponent implements OnInit {

    /*
    * @todo вынести общие для всех модлаьных окон свойства и методы в парент и отнаследовать, стили тоже
    * */

    private modalController: ModalController;
    @Input() text: any = '';

    constructor(
        modalController: ModalController
    ) {
        this.modalController = modalController;
    }

    ngOnInit() {
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

}
