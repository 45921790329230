import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {DefaultAddress, UserProfile} from '../entity/profile.entity';
import {Comment} from '../entity/comment.entity';
import {Address} from '../entity/address.entity';
import {HistoryService} from './history.service';
import {SurveyService} from './survey.service';
import {CatalogService} from './catalog.service';
import {CartService} from './cart.service';
import {CartDeliver, DeliveryType, OrderStep} from '../entity/cart.entity';
import {ApiResponse} from '../entity/apiResponse.entity';
import {CommentService} from './comment.service';
import {AddressService} from './address.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends MainService {

    user: UserProfile;
    registrData: any;
    token: string | null;
    comments: Comment[] = [];
    addresses: Address[] = [];

    historyService: HistoryService;
    surveyService: SurveyService;
    catalogService: CatalogService;
    cartService: CartService;
    commentService: CommentService;
    addressService: AddressService;

    smsData: any;
    isHasDefaultAddress = false;

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService,
        historyService: HistoryService,
        surveyService: SurveyService,
        cartService: CartService,
        commentService: CommentService,
        addressService: AddressService,
        catalogService: CatalogService
    ) {
        super(http, alert, loader, appService);

        this.historyService = historyService;
        this.surveyService = surveyService;
        this.catalogService = catalogService;
        this.cartService = cartService;
        this.commentService = commentService;
        this.addressService = addressService;

        this.user = new UserProfile();
    }

    private urlRequest = 'personal';
    public phone = '';

    makePostRequest(data, callback) {
        if (data.hasOwnProperty('login')) {
            this.phone = data.login;
        }
        this.postRequest(data, callback, this.urlRequest);
    }

    resetPhone() {
        this.phone = '';
    }

    // @todo переписать
    // обработка данных после логина, сохранение в модель и в локал сторейдж
    private processLogin(res, callback: (status: boolean, errorText: string, isCode: boolean) => any) {
        let status: boolean;
        let errorText = '';
        let isCode = false;

        if (res.result?.id) {
            this.user = res.result;
            this.token = res.result.id;
            localStorage.setItem('ru.z2s.app__token', res.result.id);

            // TODO убрать куски с городом?

            // if (res.city === null && res.city !== 'undefined' && typeof res.city !== 'undefined') {
            //     localStorage.setItem('ru.z2s.app__city', res.city);
            // }
            //
            // if (typeof res.city !== 'undefined') {
            this.cartService.sync(this.cartService.cart?.products?.length ? OrderStep.Sync : OrderStep.Start, (s) => {
                this.cartService.updateQuantity$.emit(null);
            });
            // }

            status = true;
        } else if (res.code) {
            this.registrData = res;
            status = false;
            errorText = '';
            isCode = true;
        } else if (res.success) {
            status = true;
            errorText = res.message;
            isCode = false;
        } else {
            status = false;
            errorText = res.message;
            isCode = false;
        }

        callback(status, errorText, isCode);
    }

    // получение базовых данных юзера
    public getUser(callback: (status: boolean) => any, force = true) {
        // this.token = localStorage.getItem('ru.z2s.app__token');
        const data = {
            action: 'getProfile'
        };

        if (force || this.user.id.toString() === '0') {
            // this.postRequest(data, (res: UserProfile) => {
            this.postRequest(data, (res: any) => {
                res = res.result;

                if (res.id) {
                    this.user = res;
                    this.isHasDefaultAddress = this.isHasDefaultAddress || !!res.defaultAddress;
                }

                callback(true);
            }, this.urlRequest);
        } else {
            callback(true);
        }

    }

    // основной метод логина
    public login(data: any,
                 callback: (
                     status: boolean,
                     errorText: string,
                     isCode: boolean
                 ) => any,
                 message: boolean = true,
                 loading: boolean = true
    ) {
        let errorText: string;

        if (data.hasOwnProperty('login')) {
            errorText = this.validationPhone(data.login);

            if (errorText) {
                callback(false, errorText, false);
                return;
            }

            this.phone = data.login;
        }

        this.postRequest(data, (res) => {
            this.processLogin(res, callback);
        }, this.urlRequest, message, loading);

    }

    passwordRecovery(data, callback: (response: ApiResponse) => any) {
        this.regJsonRequest<ApiResponse>(data, (status, answer) => {
            callback(answer);
        }, false, true);
    }

    checkPhone(data, callback: (response: ApiResponse) => any) {

        data.action = 'checkPhone';

        this.regJsonRequest<ApiResponse>(data, (status, answer) => {
            callback(answer);
        }, false, true);
    }

    validationPhone(phone) {
        let errorText = '';
        if (!phone || phone.length !== 10) {
            errorText = 'Телефон указан некорректно';
        }

        return errorText;
    }

    // Отправка токена устройства на сервер, оставим на вырост
    public addDevice(device: string, type: string) {

    }

    // Удаление токена устройства с серверп, оставим на вырост
    public removeDevice(device: string) {

    }

    // Логин при входе в апу, у нас это получение профиля
    makeLogin(callback: (status: boolean) => any) {

        this.token = this.appService.getUserToken();

        if (this.token !== 'null' && this.token !== 'undefined' && this.token) {

            this.getUser(res => {
                if (res) {
                    callback(res);
                } else {
                    this.logout(() => {
                        callback(false);
                    });
                }

            });

        } else {
            callback(false);
        }
    }

    public setUsername(data, callback: (status: boolean) => void) {
        const resData = {
            action: 'register2Phone',
            name: data.username,
            last_name: data.last_name
        };

        this.regJsonRequest(resData, status => {
                if (status) {
                    this.getUser(callback);
                }
            }, false, true
        );
    }

    public saveNotification(notificationValue, callback: (status: boolean) => void, loading = false) {
        const data = {
            action: 'saveNotification',
            profile: notificationValue
        };

        this.regJsonRequest(data, status => {
                if (status) {
                    this.getUser(callback);

                }
            }, false, loading
        );
    }

    public updateProfile(callback: (status: boolean) => void, loading = false) {
        const data = {
            action: 'saveProfile',
            profile: this.user
        };

        this.regJsonRequest(data, status => {
                if (status) {
                    this.getUser(callback);
                }
            }, false, loading
        );
    }

    public changePassword(data, callback: (answer: { message: string, status: true }) => void, loading = false) {

        data.action = 'changePassword';

        this.postRequest(data, (answer: { message: string, status: true }) => {

            callback(answer);

        }, this.urlRequest, false, loading);
    }

    // Проверка если юзер залогинен по айди в обьекте профиля
    public isLogin() {
        return this.user?.id?.toString() !== '0';
    }

    logout(callback: (status: boolean) => void) {

        this.appService.unSubscribePush(this.user.userId);

        /*
        * @TODO добавить остальное обнуление данных
        * */

        localStorage.removeItem('ru.z2s.app__token');
        this.user = new UserProfile();
        this.surveyService.ordersWithSurveyCount = '0';
        this.catalogService.clearSelectedSideDish();

        this.historyService.clean();
        this.commentService.clean();
        this.addressService.clean();

        this.cartService.newCart();
        this.cartService.updateQuantity$.emit(null);

        callback(true);

        // if (this.catalogService.birthdayText) {
        this.catalogService.getCatalog(status => {

        }, true);
        // }

    }

    public checkCode(code: any, callback: (status: boolean, result: any) => void) {
        this.getRequest(
            {
                code
            },
            (response: ApiResponse) => {
                callback(response.status, response.result);
            }, 'personal/code', true, true);
    }

    saveDefaultAddress(address: DefaultAddress, callback?: (type: 'address' | 'catalog') => void, loading = true, message = false) {
        this.postRequest({defaultAddress: address}, (res) => {
            if (res?.status && address) {
                this.user.defaultAddress = address;
                this.isHasDefaultAddress = true;

                if (!this.cartService.cart.delivery) {
                    this.cartService.cart.delivery = new CartDeliver();
                }
                switch (address.type) {
                    case DeliveryType.Pickup:
                        this.cartService.cart.delivery.pickup = address.pickup;
                        break;
                    case DeliveryType.Courier:
                        this.cartService.cart.delivery.courier = address.courier;
                        if (address.courierPoint) {
                            this.cartService.cart.delivery.courierPoint = address.courierPoint;
                        }
                        break;
                }
                this.cartService.cart.delivery.type = address.type;

                callback('address');
                this.catalogService.getCatalog(() => {
                    this.appService.changeMenu();
                    callback('catalog');
                }, true);
            }
        }, this.getUrl('personal/defaultAddress'), message, loading);
    }
}
