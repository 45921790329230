import {
    ChangeDetectorRef,
    Component,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {AppService} from '../../services/app.service';
import {UserService} from '../../models/user.service';
import {NavigationService} from '../../services/navigation.service';
import {WokService} from 'src/app/models/wok.service';
import {WokItem} from 'src/app/entity/wokItem.entity';
import {CartService} from 'src/app/models/cart.service';
import {AnalyticsService} from '../../services/analytics.service';
import {MainModal} from '../../core/main.modal';
import {ModalService} from '../../services/modal.service';
import {GestureController} from '@ionic/angular';
import Scrollbar from 'smooth-scrollbar';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';


@Component({
    selector: 'wok-modal',
    templateUrl: 'wok-modal.html',
    styleUrls: ['wok-modal.scss'],
    encapsulation: ViewEncapsulation.None
})
export class WokModalComponent extends MainModal implements OnInit, OnDestroy {

    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    @Input() wokId: number;
    @Input() withVegetables: boolean;

    public pageReady = false;

    public quantity = 1;
    public wok = [];

    cdr: ChangeDetectorRef;
    private wokService: WokService;
    private cartService: CartService;
    scrollY = 50;
    opacity = 0;

    selectedStep = 'base';
    step = 0;
    isFinishSection: boolean;
    stepNames = [
        {value: 'base', title: 'Основа'},
        {value: 'supplements', title: 'Добавки'},
        {value: 'sauces', title: 'Соусы'},
        {value: 'more', title: 'Дополнительно'}
    ];

    activeBaseName = '';
    checkedItemName = '';
    selectedBaseItem: WokItem;
    selectedSauceItem: WokItem;
    selectedItems = [];
    isCheckedVegetables = false;
    navigationService: NavigationService;
    userService: UserService;
    private scrollBar: Scrollbar;

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        navigationService: NavigationService,
        userService: UserService,
        wokService: WokService,
        cartService: CartService,
        cdr: ChangeDetectorRef,
    ) {
        super(appService, modalService, gestureCtrl);
        this.onScroll = this.onScroll.bind(this);

        this.wokService = wokService;
        this.cdr = cdr;
        this.cartService = cartService;
    }

    @HostListener('document:touchmove', ['$event'])
    onTouchMove(event: Event) {
        event.stopPropagation();
    }

    loadWokFromCart() {
        const cartItem = JSON.parse(JSON.stringify(this.cartService.cart.products.find(wok => wok.id === this.wokId)));

        this.selectedItems = cartItem.options;
        this.selectedBaseItem = cartItem.options.find(v => v.step === 0);
        this.checkedItemName = this.selectedBaseItem.name ? this.selectedBaseItem.name : '';
        this.quantity = cartItem.qty;
    }

    ngOnInit() {
        AnalyticsService.pageVisit('Вок Конструктор');

        if (this.wokId !== undefined) {
            this.loadWokFromCart();
        }

        if (this.withVegetables) {
            this.isCheckedVegetables = this.withVegetables;
        }

        this.wokService.getWok(status => {
            if (status) {

                this.pageReady = true;
                this.wok = this.wokService.wokConstructor;

                if (this.wokId !== undefined) {
                    this.changeWokConstructor();
                }

                setTimeout(() => {
                    this.createVirtualScroll(this.scrollContainer.nativeElement);
                });
            }
        });
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll);
        this.onScroll({offset: {x: 0, y: 0}});
    }

    changeWokConstructor() {
        this.wok = this.wok.map(step => {
            return step.map(item => {
                this.selectedItems.forEach(i => {
                    if (i.id.toString() === item.id.toString()) {
                        item = i;
                    }

                    if (i.step === 3 && !i.isDopSous) {
                        this.isCheckedSauce(i, true);
                    }
                });
                return item;
            });
        });

        this.setActiveBase();
    }

    isCheckedItem(itemId) {
        return !!this.selectedItems.find(v => v.id === itemId);
    }

    onScroll(e: any) {
        this.modalService.scrollModal(e.offset.y);
    }

    ionViewWillEnter() {
        this.appService.hideFooterMenu = true;

        // this.pageReady = true;
    }

    onSelectItem(e: Event, stepIdx: number, itemId: string) {
        const isChecked = (e.target as HTMLInputElement).checked;
        let selectedItem;

        if (stepIdx === -1) {
            const noVet: any = this.selectedItems.filter((item) => {
                return item.code === 'bez-ovoshchey';
            })[0];

            if (noVet) {
                noVet.checked = isChecked;
            } else {
                selectedItem = this.wok[4][0];
                selectedItem.step = -1;
            }

            this.isCheckedVegetables = isChecked;

            this.setActiveBase();
        } else {
            this.step = stepIdx;
            selectedItem = this.wok[stepIdx].find(i => i.id === itemId);
            selectedItem.step = stepIdx;

            if (stepIdx === 0) {
                this.checkedItemName = selectedItem.name;
            }
        }

        if (selectedItem) {
            selectedItem.checked = isChecked;
            selectedItem.quantity = 1;
        }

        // выбор основы
        if (stepIdx === 0) {

            if (this.selectedItems.length && this.selectedBaseItem) {
                const unCheckedItemIdx = this.selectedItems.findIndex(item => item.id === this.selectedBaseItem.id);
                this.selectedItems.splice(unCheckedItemIdx, 1, selectedItem);
            } else {
                this.addToSelectedItems(selectedItem);
            }

            this.selectedBaseItem = selectedItem;
            this.setActiveBase();
        } else {

            // остальные этапы сборки WOK
            if (selectedItem) {
                this.changeSelectedItems(isChecked, selectedItem);
            }

        }

        // выбор соуса / установка соусов в disable
        if (stepIdx === 2 && !selectedItem.isDopSous) {

            if (isChecked) {
                this.selectedSauceItem = selectedItem;
            } else {
                this.selectedSauceItem = undefined;
            }

            this.isCheckedSauce(selectedItem, isChecked);
        }
    }

    setActiveBase() {
        this.wok[3].map(item => {
            if (item.type?.toString() !== 'vegetables') {
                item.disabled = this.selectedBaseItem?.type && item.type && item.type !== this.selectedBaseItem?.type;
            } else {
                item.disabled = this.isCheckedVegetables;
            }

            if (this.selectedBaseItem?.type && item.type && item.type !== this.selectedBaseItem?.type) {
                item.checked = false;
                this.changeSelectedItems(false, item);
            }
        });
    }

    isCheckedSauce(selectedItem, isChecked) {
        this.wok[2].map(item => {
            if (!item.isDopSous && item.id !== selectedItem.id) {
                item.disabled = !!isChecked;
            }
        });
    }

    changeSelectedItems(isChecked: boolean, selectedItem: any) {
        if (isChecked) {
            this.addToSelectedItems(selectedItem);
        } else {
            const unCheckedItemIdx = this.selectedItems.findIndex(item => item.id === selectedItem.id);
            if (unCheckedItemIdx !== -1) {
                this.selectedItems.splice(unCheckedItemIdx, 1);
            }
        }
    }

    addToSelectedItems(selectedItem) {
        this.selectedItems.push(selectedItem);
        this.selectedItems = this.selectedItems.sort((a, b) => a.step - b.step);
    }

    switchSection(stepName: string) {

        if (this.isFinishSection) {
            this.isFinishSection = false;
        }

        if (this.checkedItemName) {
            this.step = this.stepNames.findIndex(s => s.value === stepName);
            this.selectedStep = stepName;
        }
    }

    nextStep() {
        if (this.checkedItemName) {
            this.step += 1;
            this.selectedStep = this.stepNames[this.step].value;
        }
    }

    increase(event: Event) {
        event.stopPropagation();
        this.quantity += 1;
    }

    decrease(event: Event) {
        event.stopPropagation();
        if (this.quantity > 1) {
            this.quantity -= 1;
        }
    }

    get totalPrice() {
        if (!this.selectedItems.length) {
            return 0;
        } else {
            return this.selectedItems.reduce((total, item) => total + item.price * item.quantity, 0);
        }
    }

    get totalOldPrice() {
        if (!this.selectedItems.length) {
            return 0;
        } else {
            return this.selectedItems.reduce((total, item) => total + item.oldPrice * item.quantity, 0);
        }
    }

    finishWok() {
        this.selectedStep = null;
        this.step = null;
        this.isFinishSection = true;
    }

    resetWok() {

        this.selectedItems.length = 0;
        this.quantity = 1;
        this.checkedItemName = '';
        this.selectedSauceItem = null;

        if (this.wokId !== undefined) {
            this.loadWokFromCart();
        }

        this.isFinishSection = false;
        this.step = 0;
        this.selectedStep = 'base';

        this.changeWokConstructor();

        // this.cdr.detectChanges();

    }

    minusItem(item) {
        if (item.quantity > 1) {
            item.quantity -= 1;
        }
    }

    plusItem(item) {
        if (item.quantity < item.maxQty) {
            item.quantity += 1;
        }
    }

    genWokId() {
        const id = this.selectedItems.map(item => {
            let q = '';

            if (item.quantity > 1) {
                q = '-' + item.quantity;
            }

            return item.id + q;
        });

        return id.join('_');
    }

    addToCart() {


        if (this.wokId) {
            this.cartService.addToCart({
                id: this.wokId
            }, -10000, null, null, 0);
        }

        const resultWok = {
            id: this.genWokId(),
            name: 'WOK-конструктор',
            price: this.totalPrice,
            oldPrice: this.totalOldPrice,
            fprice: 0,
            offer: null,
            imageUrl: 'assets/img/wok.png',
        };

        if (this.wokId) {
            this.cartService.addToCart(resultWok, this.quantity, null, this.selectedItems, 0, false, (status) => {
                if (status) {
                    this.close();
                }
            });
        } else {
            this.cartService.addToCart(resultWok, this.quantity, null, this.selectedItems);

            setTimeout(() => {
                this.close();
            }, 500);
        }


    }

    logout() {
        this.userService.logout(status => {
            if (status) {
                this.navigationService.goToPage('authorization', true);
            }
        });
    }

    onClickButtonClose() {
        this.close();
    }

    close() {
        this.wokService.newWokConstructor();
        super.close();
    }

    ngOnDestroy() {
        if (this.scrollBar) {
            this.scrollBar.removeListener(this.onScroll);
            this.scrollBar.destroy();
            this.scrollBar = null;
        }
    }
}
