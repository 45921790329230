import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {BottomMenuItemComponent} from './item.component';

@NgModule({
    entryComponents: [BottomMenuItemComponent],
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        BottomMenuItemComponent
    ],
    declarations: [BottomMenuItemComponent]
})
export class BottomMenuItemModule {

}
