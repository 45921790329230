import {Entity} from '../core/main.entity';

export class AddressSearchAnswer implements Entity {
    terminalOverloaded: boolean;
    deliveryTime: string;
    pickUpTime: number;
    'terminal_id': string;
    'terminal_name': string;
    'terminal_bid': string;
    x: number;
    y: number;
    'min_price': number;
    timeOptions: {
        start: string,
        end: string,
        delay: string
    };
}
