import { Entity } from '../core/main.entity';

export class Address implements Entity {
    id = 0;
    name: string;
    street: string;
    house: string;
    building: string;
    porch: string;
    floor: string;
    office: string;
    intercom: string;
    comment: string;
    isDefaultAddress: boolean;
}
