import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { HistoryService } from 'src/app/models/history.service';
import { SurveyService } from 'src/app/models/survey.service';
import { UserService } from 'src/app/models/user.service';
import {MainItem} from '../../core/main.item';
import {CartService} from '../../models/cart.service';
import {AppService} from '../../services/app.service';
import {NavigationService} from '../../services/navigation.service';

@Component({
    selector: 'bottom-menu-item',
    templateUrl: 'item.html'
})

export class BottomMenuItemComponent extends MainItem implements OnDestroy, OnInit {

    cartService: CartService;
    historyService: HistoryService;
    appService: AppService;
    navigationService: NavigationService;
    userService: UserService;
    surveyService: SurveyService;

    userPhone: string;
    ordersWithSurveyCount: string;

    constructor(
        cartService: CartService,
        appService: AppService,
        navigationService: NavigationService,
        historyService: HistoryService,
        userService: UserService,
        surveyService: SurveyService
    ) {
        super();

        this.appService = appService;
        this.cartService = cartService;
        this.historyService = historyService;
        this.navigationService = navigationService;
        this.userService = userService;
        this.surveyService = surveyService;
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

    goToHome() {
        this.appService.resetMenuMainSwiper();
        this.navigationService.goToPage('menu', true);
    }

    goToCart() {
        this.navigationService.goToPage('cart');
    }

    goToProfile() {
        this.navigationService.goToPage('profile/main');
    }

    goToSurvey() {
        this.navigationService.goToPage('survey');
    }
}
