import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    NgZone,
    OnDestroy,
    OnInit, ViewChild
} from '@angular/core';
import {MainComponent} from '../../core/main.component';
import {AppService} from '../../services/app.service';
import {CatalogService} from '../../models/catalog.service';
import {HelperService} from '../../services/helper.service';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {UserService} from '../../models/user.service';
import {NavigationService} from '../../services/navigation.service';
import {PopupWorkTimeComponent} from '../../components/popups/popup-work-time/popup-work-time.component';
import {PopupBirthdayComponent} from '../../components/popups/popup-birthday/popup-birthday.component';
import {Subscription} from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';
import {AnalyticsService} from '../../services/analytics.service';
import * as smoothscroll from 'smoothscroll-polyfill';
import {ModalService} from '../../services/modal.service';
import {PromoModalComponent} from '../../components/promo-modal/promo-modal';
import {BannerModalComponent} from '../../components/banner-modal/banner-modal';
import {PopupSelectAddressComponent} from '../../components/popups/popup-select-address/popup-select-address';
import {DeliveryType} from '../../entity/cart.entity';

@Component({
    selector: 'app-menu',
    templateUrl: 'page.html',
    styleUrls: ['page.scss']
})
export class MenuPage extends MainComponent implements AfterViewInit, OnInit, OnDestroy {

    private slides: any[] = [];

    ready = false;
    subscriptions: Subscription[] = [];

    navSliderInit = false;
    subNavSliderInit = false;
    menuNgSliderInit = false;
    blogSliderInit = false;

    @ViewChild('navSlider') navSlider: SwiperComponent;
    navSliderOptions: SwiperConfigInterface = {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        observer: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumRatio: 0.5
    };

    @ViewChild('subNavSlider') subNavSlider: SwiperComponent;
    subNavSliderOptions: SwiperConfigInterface = {
        slidesPerView: 'auto',
        centeredSlides: true,
        centeredSlidesBounds: true,
        centerInsufficientSlides: true,
        nested: true,
        observer: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumRatio: 0.5,
        speed: 999999,
    };

    @ViewChild('menuNgSlider') menuNgSlider: SwiperComponent;
    menuNgSliderOptions: SwiperConfigInterface = {
        cssMode: !this.appService.isIos(),
        autoHeight: true,
        slidesPerView: 1,
        observer: true
    };

    blogSliderActiveIndex = 0;
    @ViewChild('blogSlider') blogSlider: SwiperComponent;
    blogSliderOptions: SwiperConfigInterface = {
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        nested: true,
        slidesPerView: 1,
        resistanceRatio: 0,
        initialSlide: -1,
        loop: true,
        observer: true,
        lazy: {
            loadPrevNext: true,
        },
    };


    selectedCat = 0;
    selectedCatForSub = 0;
    selectedCatHaveSubCategoryFlag = 0;
    subCategoryOffsets: any[] = [];
    selectedSubCategory = 0;
    showSubMenu = false;
    selectedSubCatElem: HTMLElement = null;

    scrollTimer: any;
    scrollTime = 300;
    scrollInAction = false;

    scrollMenuTimer: any;
    scrollMenuTime = 1000;
    scrollMenuInAction = false;

    menuTypeTimer: any;
    menuTypeTimerReady = true;

    catalogService: CatalogService;
    modalService: ModalService;
    cdr: ChangeDetectorRef;
    zone: NgZone;
    sanitization: DomSanitizer;

    timer: any;
    isClickedNav: boolean;
    swiperLock = true;
    phone: string;

    selectedSubCategoryIndex = {};

    DeliveryType = DeliveryType;

    isMenuLoading = false;
    private changeMenu$: Subscription;

    onIndexChangeBlogSlider(index: number) {
        this.blogSliderActiveIndex = index;
    }

    constructor(
        appService: AppService,
        navigationService: NavigationService,
        userService: UserService,
        catalogService: CatalogService,
        modalService: ModalService,
        cdr: ChangeDetectorRef,
        zone: NgZone,
        sanitization: DomSanitizer
    ) {
        super(appService, navigationService, userService);

        this.catalogService = catalogService;
        this.modalService = modalService;
        this.cdr = cdr;
        this.zone = zone;
        this.sanitization = sanitization;

        smoothscroll.polyfill();
    }

    ngAfterViewInit() {
    }

    swiperTouchStart(subNavSlider: SwiperComponent) {
        const swiper = subNavSlider.directiveRef.swiper();
        swiper.setTranslate(swiper.getTranslate());
    }

    swiperTouchMove() {
        this.menuNgSlider.directiveRef.swiper().allowTouchMove = false;
    }

    swiperTouchEnd(evt) {
        this.menuNgSlider.directiveRef.swiper().allowTouchMove = true;

        const slider = evt[0];

        setTimeout(() => {
            this.customSelectedCatAnimation(slider, slider.width, slider.slides, this.selectedSubCategory);
        }, 10);
    }

    checkBirthDay() {
        if (this.catalogService.birthdayText) {

            const s = localStorage.getItem('ru.z2s.app__btd');
            let t = 0;
            if (s) {
                t = JSON.parse(s);
            }
            const n = (new Date()).getTime();

            if (!t || (n - t) / 1000 / 60 / 60 > 6) {
                this.appService.openModal(PopupBirthdayComponent, {text: this.catalogService.birthdayText});
                localStorage.setItem('ru.z2s.app__btd', JSON.stringify((new Date()).getTime()));
            }
        }
    }

    ngOnInit() {

        this.subscriptions.push(this.appService.resetMenuSwiper$.subscribe(() => {
            this.menuNgSlider.directiveRef.setIndex(0);
            const wrapperElem = document.querySelector(`.wrapper-0`) as HTMLElement;
            wrapperElem.scroll(0, 0);
        }));

        this.phone = this.catalogService.phone;
        this.appService.menuType === 'grid' ? this.swiperLock = false : this.swiperLock = true;
    }

    ionViewWillEnter() {
        this.changeMenu$ = this.appService.changeMenu$.subscribe(() => {
            if (!this.isMenuLoading) {
                this.isMenuLoading = true;
                this.appService.showLoading();
            }

            this.appService.menuReady = false;

            this.navSliderInit = false;
            this.subNavSliderInit = false;
            this.menuNgSliderInit = false;
            this.blogSliderInit = false;

            this.selectedCat = 0;
            this.selectedCatForSub = 0;
            this.selectedCatHaveSubCategoryFlag = 0;
            this.subCategoryOffsets = [];
            this.selectedSubCategory = 0;
            this.showSubMenu = false;
            this.selectedSubCatElem = null;
            this.slides = [];

            this.scrollTimer = null;
            this.scrollInAction = false;
            this.scrollMenuTimer = null;
            this.scrollMenuInAction = false;
            this.menuTypeTimer = null;
            this.menuTypeTimerReady = true;
            this.timer = null;
            this.isClickedNav = false;


            this.blogSliderActiveIndex = 0;

            setTimeout(() => {
                this.appService.menuReady = true;
                this.cdr.detectChanges();
            });
        });
        if (this.appService.isMenuChanged) {
            this.appService.changeMenu();
        }
        if (this.appService.menuType === 'list') {
            AnalyticsService.pageVisit('Меню Список');
        } else {
            AnalyticsService.pageVisit('Меню');
        }

        this.appService.hideFooterMenu = false;
        this.checkBirthDay();
    }

    ionViewDidLeave() {
        if (this.changeMenu$) {
            this.changeMenu$.unsubscribe();
            this.changeMenu$ = null;
        }
    }

    menuReady() {
        this.afterMenuTypeChange();
        this.checkBirthDay();
        // this.showModalWorkTime(); // disabled into Z2S-314
        this.appService.isMenuChanged = false;

        // Показываем меню, после окончания рендера (после смены defaultAddress)
        this.appService.menuOpacity = 1;
        if (this.isMenuLoading) {
            this.isMenuLoading = false;
            this.appService.hideLoading();
        }
    }

    showModalWorkTime() {
        const now = new Date();
        let h = (now.getUTCHours() + 3).toString(); // получение времени с учетом таймзоны москвы
        let m = now.getMinutes().toString();
        const timeStart = '11:00';
        const timeEnd = '23:00';
        if (h.length < 2) {
            h = '0' + h;
        }
        if (m.length < 2) {
            m = '0' + m;
        }

        const nn = h + ':' + m;

        if ((nn < timeStart || nn > timeEnd) && !this.appService.workTimeIsShowed) {
            this.appService.workTimeIsShowed = true;
            this.appService.openModal(PopupWorkTimeComponent, null, (data) => {

            });
        }
    }

    checkForReady() {
        const hasSubCategories = this.catalogService.categories.findIndex(i => i.subCategories?.length) > -1;
        if (this.navSliderInit &&
            this.menuNgSliderInit &&
            this.blogSliderInit &&
            (this.subNavSliderInit || !hasSubCategories) &&
            this.appService.menuReady) {

            this.menuReady();
        }
    }

    navSliderReady() {
        this.navSliderInit = true;
        this.navSlider.config = this.navSliderOptions;
        this.checkForReady();
    }

    subNavSliderReady(subNavSlider: SwiperComponent, index: number) {
        this.subNavSliderInit = true;
        this.slides[index] = subNavSlider;
        this.subNavSlider.config = this.subNavSliderOptions;
        if (this.slides[this.selectedCat]) {
            this.slides[this.selectedCat].directiveRef.swiper().slideTo(this.selectedSubCategory);
        }
        this.checkForReady();
        this.slides.forEach((el, i) => {
            const slider = this.slides[i].directiveRef.swiper();

            if (slider) {
                this.customSelectedCatAnimation(slider, slider.width, slider.slides, 0);
            }

        });
    }

    menuNgSliderReady() {
        this.menuNgSliderInit = true;
        this.checkForReady();
    }

    blogSliderReady() {
        this.blogSliderInit = true;
        this.checkForReady();
    }

    menuNgSlideChange(index: number) {
        if (!this.isClickedNav) {
            this.setNavSlider(index, false);
        }
    }

    menuSwiperTouchMove() {
        if (this.scrollMenuTimer) {
            clearTimeout(this.scrollMenuTimer);
        }
        this.scrollMenuInAction = false;
        this.isClickedNav = false;
    }

    onClickNavSlider(index: number) {
        this.isClickedNav = true;
        this.setNavSlider(index);
    }

    setNavSlider(index: number, changeMenu = true) {
        this.selectedCat = index;
        this.selectedCatHaveSubCategoryFlag = this.selectedCatHaveSubCategory();

        if (changeMenu && this.menuNgSlider) {
            this.menuNgSlider.directiveRef.setIndex(index);
            this.scrollMenuInAction = true;
        }

        this.navSlider.directiveRef.swiper().slideTo(index);
        this.getSelectedSubCategory(index);
    }

    scrollToCat(subCatId: number, index: number, evt: any) {
        evt.stopPropagation();

        const wrapperElem = document.querySelector(`.wrapper-${this.selectedCat}`) as HTMLElement;
        this.selectedSubCatElem = wrapperElem.querySelector(`.product-sub-category-${subCatId}`) as HTMLElement;

        wrapperElem.scrollTo({
            top: this.selectedSubCatElem.offsetTop - 43,
            left: 0,
            behavior: 'smooth'
        });

        this.selectedSubCategory = index;
        if (this.slides[this.selectedCat]) {
            const slider = this.slides[this.selectedCat].directiveRef.swiper();
            this.slides[this.selectedCat].directiveRef.swiper().slideTo(this.selectedSubCategory);
            this.customSelectedCatAnimation(slider, slider.width, slider.slides, index);
        }

        this.selectedSubCategoryIndex[this.selectedCat] = this.selectedSubCategory;

        if (this.scrollTimer) {
            clearTimeout(this.scrollTimer);
        }

        this.scrollInAction = true;
    }

    getSelectedSubCategory(productCatIndex: number) {
        if (this.selectedSubCategoryIndex[productCatIndex] !== undefined) {
            this.selectedSubCategory = this.selectedSubCategoryIndex[productCatIndex];
        } else {
            this.selectedSubCategory = 0;
        }
        if (this.slides[this.selectedCat]) {
            this.slides[this.selectedCat].directiveRef.swiper().slideTo(this.selectedSubCategory);
        }

        this.cdr.detectChanges();
    }

    selectedCatHaveSubCategory() {
        return this.catalogService.categories[this.selectedCat] &&
            this.catalogService.categories[this.selectedCat].subCategories &&
            this.catalogService.categories[this.selectedCat].subCategories.length;
    }

    onPageScroll(evt: any) {
        evt.stopPropagation();
        const scrollElem = evt.target as HTMLElement;

        // проверка остановки скролла при клике по меню подкатегорий
        if (this.selectedSubCatElem !== null && this.scrollInAction) {

            const scrollElemTop = scrollElem.scrollTop;
            const scrollElemBottom = scrollElem.scrollTop + scrollElem.clientHeight;

            if ((this.selectedSubCatElem.offsetTop === scrollElemTop + 43) ||
                (this.selectedSubCatElem.offsetTop < scrollElemBottom &&
                    this.selectedSubCatElem.offsetTop > scrollElemTop + 43)) {   // values: 43 - height header, 70 - height empty div

                setTimeout(() => {
                    this.scrollInAction = false;
                }, 300);

                this.selectedSubCatElem = null;
            }
            return;
        }

        if (this.selectedCatHaveSubCategoryFlag && !this.scrollInAction) {
            const subCategories = this.catalogService.categories[this.selectedCat].subCategories;
            let activeIndex = 0;
            for (let i = 0; i < subCategories.length; i++) {

                const wrapperElem = document.querySelector(`.wrapper-${this.selectedCat}`) as HTMLElement;
                const subCatElem = wrapperElem.querySelector(`.product-sub-category-${subCategories[i].id}`) as HTMLElement;

                const val = subCatElem.offsetTop;

                if (val - scrollElem.scrollTop <= 43) {
                    activeIndex = i;
                }

                if ((scrollElem.scrollHeight - scrollElem.scrollTop === scrollElem.clientHeight) &&
                    (subCatElem.offsetTop + subCatElem.clientHeight - 43 + 70 === scrollElem.scrollHeight)) {
                    activeIndex = i;
                }
            }

            if (activeIndex !== this.selectedSubCategory) {
                this.selectedSubCategory = activeIndex;
                if (this.slides[this.selectedCat]) {

                    const slider = this.slides[this.selectedCat].directiveRef.swiper();

                    slider.slideTo(this.selectedSubCategory);
                    this.customSelectedCatAnimation(slider, slider.width, slider.slides, activeIndex);
                }
                this.selectedSubCategoryIndex[this.selectedCat] = this.selectedSubCategory;
            }
        }
    }

    swiperAnimated(e) {
        let slider = this.slides[e.activeIndex];

        if (slider) {
            slider = slider.directiveRef.swiper();

            this.customSelectedCatAnimation(slider, slider.width, slider.slides, slider.activeIndex);
        }
    }

    customSelectedCatAnimation(slider, containerWidth, slides, activeIndex = 0) {
        let activeSlideOffsetLeft = 0;
        let trf: number;
        slides.forEach((el, i) => {
            if (i <= activeIndex) {
                if (i === activeIndex) {
                    activeSlideOffsetLeft += el.offsetWidth / 2;
                } else {
                    activeSlideOffsetLeft += el.offsetWidth;
                }
            } else {
                return false;
            }
        });
        trf = containerWidth / 2 - activeSlideOffsetLeft;
        slider.translateTo(trf, 300, false, false);
    }

    navSliderChange() {
        this.showSubMenu = !!this.selectedCatHaveSubCategoryFlag;
    }

    toggleMenuType() {
        this.appService.menuType = this.appService.menuType === 'list' ? 'grid' : 'list';
        localStorage.setItem('ru.z2s.app__menuType', this.appService.menuType);

        for (const prop in this.selectedSubCategoryIndex) {
            if (this.selectedSubCategoryIndex.hasOwnProperty(prop)) {
                delete this.selectedSubCategoryIndex[prop];
            }
        }

        this.menuTypeTimerReady = false;
        if (this.menuTypeTimer) {
            clearTimeout(this.menuTypeTimer);
        }

        if (this.menuNgSlider) {
            this.menuNgSlider.directiveRef.setIndex(0, 0);
            this.setNavSlider(0);
            this.lockNavSwiper(this.appService.menuType === 'list');
        }

        setTimeout(() => {
            this.menuTypeTimerReady = true;
            this.afterMenuTypeChange();
        }, 600);
    }

    afterMenuTypeChange() {
        if (this.menuNgSlider) {
            this.lockNavSwiper(this.appService.menuType === 'list');
            this.menuNgSlider.directiveRef.update();
        }
    }

    getLazyDefaultImage(id) {
        return HelperService.getLazyDefaultImage(id);
    }

    lockNavSwiper(lock) {
        this.swiperLock = lock;
        this.menuNgSlider.directiveRef.swiper().allowSlideNext = !lock;
        this.menuNgSlider.directiveRef.swiper().allowSlidePrev = !lock;
        this.menuNgSlider.directiveRef.swiper().allowTouchMove = !lock;
    }

    onClickBlogSlider() {
        this.openPromoModal();
    }

    openPromoModal() {
        const activePromoSlide = this.catalogService.promos[this.blogSliderActiveIndex];
        this.modalService.createModal(PromoModalComponent, {promoId: activePromoSlide.id});
    }

    openBannerModal(banner) {
        this.modalService.createModal(BannerModalComponent, {banner});
    }

    onSearch() {
        this.navigationService.goToPage('search-page');
    }

    ngOnDestroy() {
        for (const prop in this.selectedSubCategoryIndex) {
            if (this.selectedSubCategoryIndex.hasOwnProperty(prop)) {
                delete this.selectedSubCategoryIndex[prop];
            }
        }
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.length = 0;
    }

    getBackgroundImageCss(imageUrl: string) {
        return this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }

    getCatalogFlipperRows(chunk: any[], size) {
        const newArray = [];
        if (chunk?.length) {
            for (let i = 0; i < chunk.length; i += size) {
                newArray.push(chunk.slice(i, i + size));
            }
        }
        return newArray;
    }

    trackChuck(index, item) {
        let t = '';
        for (const i of item) {
            t += i.id.toString();
        }
        return t;
    }

    addressForHeader() {
        let text = '';
        switch (this.userService.user?.defaultAddress?.type) {
            case DeliveryType.Pickup:
                text = this.userService.user.defaultAddress.pickup?.street;
                break;
            case DeliveryType.Courier:
                text = this.userService.user.defaultAddress?.courier?.street && this.userService.user.defaultAddress?.courier?.house ?
                    this.userService.user.defaultAddress.courier.street + ', д.' + this.userService.user.defaultAddress?.courier?.house :
                    '';
                break;
        }
        const div = document.createElement('div');
        div.innerHTML = text;
        text = div.textContent || div.innerText || '';
        return (text);
    }

    changeDefaultAddress(e) {
        e.preventDefault();
        // Открываем попап для выбора адреса
        this.appService.openModal(PopupSelectAddressComponent, {afterCatalog: false}, () => {
        }, false);
    }

    getDynamicRenderer(container: HTMLDivElement) {
        const productHeight = 244;
        const currentScroll = container.scrollTop;
        const screenHeight = document.querySelector('body').offsetHeight;
        const offsetElem: HTMLDivElement = container.querySelector('.main-menu-slide-flipper-wrapper');
        let offset = 0;
        if (offsetElem) {
            offset = offsetElem.offsetTop;
        }

        const countVisibleBlock = Math.ceil(screenHeight / productHeight) * 2.5; // прогружаем по 2.5 экрана по сторонам сверху и снизу

        const currentIndex = Math.floor((currentScroll - offset) / productHeight);
        return {
            index: 1,
            start: currentIndex - countVisibleBlock,
            end: currentIndex + countVisibleBlock
        };
    }

}
