import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppService} from 'src/app/services/app.service';
import {CartService} from 'src/app/models/cart.service';

@Component({
    selector: 'app-popup-time-picker',
    templateUrl: './popup-time-picker.component.html',
    styleUrls: ['./popup-time-picker.component.scss'],
})
export class PopupTimePickerComponent implements OnInit, OnDestroy {

    private modalController: ModalController;
    public appService: AppService;
    public cartService: CartService;

    @Input() value: string;
    @Input() timeTodayStart: string;
    @Input() timeStart: string;
    @Input() timeEnd: string;
    @Input() timeDelay: string;
    @Input() mode: 'delivery' | 'pickup';
    @Input() notTodayPickUp: boolean;

    // т.к. применяется два пикера для сегодня/не-сегодня, доп переменная нужна чтобы избежать бага: если открыть попап
    // и не меняя время сохранить, попап передаст значение второго, позднее созданного пикера (завтрашнего). Если при этом
    // была выбрана вкладка "сегодня", то время может не пройти валидацию и сбросится.
    valueNotToday = this.value;

    constructor(
        modalController: ModalController,
        appService: AppService,
        cartService: CartService,
    ) {
        this.modalController = modalController;
        this.appService = appService;
        this.cartService = cartService;
    }

    ngOnInit() {
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    ngOnDestroy() {
    }

    setTime() {
        this.modalController.dismiss({
            action: 'setTime',
            mode: this.mode,
            value: this.notTodayPickUp ? this.valueNotToday : this.value,
            notToday: this.notTodayPickUp
        });
    }

    setDay(bool) {
        this.notTodayPickUp = bool;
    }
}
