import {
    Component,
    OnInit,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    Input
} from '@angular/core';
import {AppService} from '../../services/app.service';
import {CartService} from 'src/app/models/cart.service';
import {SwiperPaginationInterface, SwiperDirective} from 'ngx-swiper-wrapper';
import {CatalogService} from 'src/app/models/catalog.service';
import {UserService} from '../../models/user.service';
import {Promotion} from 'src/app/entity/promotion.entity';
import {MainModal} from '../../core/main.modal';
import {GestureController} from '@ionic/angular';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'promo-modal',
    styleUrls: ['./promo-modal.scss'],
    templateUrl: 'promo-modal.html'
})
export class PromoModalComponent extends MainModal implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(SwiperDirective, {static: false})
    directiveRef?: SwiperDirective;

    @Input() promoId: any;

    public appService: AppService;
    public modalService: ModalService;
    protected gestureCtrl: GestureController;
    public userService: UserService;
    cartService: CartService;
    catalogService: CatalogService;
    promoModalTitle: string;
    promotions = {};
    modalPromotions: Promotion[] = [];
    activePromo: Promotion;
    initialSlide: number;
    virtualData: any;
    modalSwiperConfig = {};

    setLoaded = false;

    private pagination: SwiperPaginationInterface = {
        el: '.modal-pagination',
        clickable: true,
        hideOnClick: false,
        dynamicBullets: true,
        dynamicMainBullets: 10
    };

    constructor(
        appService: AppService,
        modalService: ModalService,
        gestureCtrl: GestureController,
        cartService: CartService,
        catalogService: CatalogService,
    ) {
        super(appService, modalService, gestureCtrl);
        this.catalogService = catalogService;
        this.cartService = cartService;
    }

    ngOnInit() {
        this.modalPromotions = this.catalogService.promos;
        this.initialSlide = this.modalPromotions.findIndex(elem => elem.id === this.promoId);
        this.activePromo = this.modalPromotions[this.initialSlide];
    }

    ngAfterViewInit() {

        setTimeout(() => this.modalSwiperConfig = {
            slidesPerView: 1,
            threshold: 10,
            pagination: this.pagination,
            virtual: {
                slides: this.modalPromotions,
                renderExternal: data => {
                    this.virtualData = data;
                }
            }
        });
    }

    indexChange() {
        this.activePromo = this.modalPromotions[this.directiveRef.getIndex()];
        this.directiveRef.update();
    }

    ngOnDestroy() {
    }


    onClickButtonClose() {
        this.close();
    }

    swiperTouchStart() {
        this.lockGesture = true;
        this.modalService.isGesture = true;
    }

    swiperTransitionEnd() {
        this.lockGesture = false;
        this.modalService.isGesture = false;
    }
}
