import {AppService} from '../services/app.service';
import {Platform} from '@ionic/angular';
import {ModalController} from '@ionic/angular';
import {Injectable, OnInit} from '@angular/core';
import {UserService} from '../models/user.service';
import {NavigationService} from '../services/navigation.service';

@Injectable()
export abstract class MainComponent implements OnInit {

    public objectKeys = Object.keys;
    public appService: AppService;
    public userService: UserService;
    public platform: Platform;
    public modal: ModalController;
    public navigationService: NavigationService;

    constructor(
        appService: AppService,
        navigationService: NavigationService,
        userService: UserService,
    ) {
        this.appService = appService;
        this.navigationService = navigationService;
        this.userService = userService;
    }

    ngOnInit(): void {
    }
}
