import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DeliveryType, OrderStep} from '../../../entity/cart.entity';
import {CartService} from '../../../models/cart.service';

@Component({
    selector: 'app-popup-unavailable-products',
    templateUrl: './popup-unavailable-products.component.html',
    styleUrls: ['./popup-unavailable-products.component.scss'],
})
export class PopupUnavailableProductsComponent implements OnInit {
    private modalController: ModalController;
    cartService: CartService;

    @Input() noAction = false;

    constructor(
        modalController: ModalController,
        cartService: CartService,
    ) {
        this.modalController = modalController;
        this.cartService = cartService;
    }

    ngOnInit() {
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    onCancel() {
        this.modalController.dismiss({action: 'canceled'});
    }


    // переход на последнюю использованную страницу каталога (Собрать заказ заново)
    pressBtn(action: 'goToCatalog' | 'continue') {
        this.modalController.dismiss({action});
    }


}
