import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {CartGuard} from './core/guards/cart.guard';

const routes: Routes = [
    {
        path: 'menu',
        loadChildren: () => import('./pages/menu/page.module').then(m => m.MenuPageModule),
        data: {
            auth: true,
            title: 'Меню',
            icon: 'menu',
            menu: true
        }
    },
    {
        path: 'promos',
        loadChildren: () => import('./pages/promos/page.module').then(m => m.PromosPageModule),
        data: {
            auth: true,
            title: 'Акции',
            icon: 'discount',
            menu: true
        }
    },
    {
        path: 'delivery',
        loadChildren: () => import('./pages/delivery/page.module').then(m => m.DeliveryPageModule),
        data: {
            auth: true,
            title: 'Доставка',
            icon: 'delivery',
            menu: true
        }
    },
    {
        path: 'branches',
        loadChildren: () => import('./pages/branches/page.module').then(m => m.BranchesPageModule),
        data: {
            auth: true,
            title: 'Контакты',
            icon: 'contacts',
            menu: true
        }
    },
    {
        path: 'branches/:id',
        loadChildren: () => import('./pages/branches/branch/page.module').then(m => m.BranchPageModule),
        data: {
            auth: true
        }
    },
    // {
    //     path: 'about_us',
    //     loadChildren: () => import('./pages/about_us/page.module').then(m => m.AboutUsPageModule),
    //     data: {
    //         auth: false,
    //         title: 'О нас',
    //         icon: 'about',
    //         menu: true
    //     }
    // },
    {
        path: 'authorization',
        loadChildren: () => import('./pages/authorization/page.module').then(m => m.AuthorizationPageModule),
        data: {
            auth: false,
            title: 'Авторизация',
            icon: 'registr',
            menu: true
        }
    },
    {
        path: 'default_address',
        loadChildren: () => import('./pages/default-address/page.module').then(m => m.DefaultAddressPageModule),
        data: {
            auth: true,
        }
    },
    {
        path: 'about_us/confidence',
        loadChildren: () => import('./pages/about_us/confidence/page.module').then(m => m.AboutUsConfidencePageModule),
        data: {
            auth: false
        }
    },
    {
        path: 'profile/main',
        loadChildren: () => import('./pages/profile/main/page.module').then(m => m.ProfileMainPageModule),
        data: {
            auth: true,
            title: 'Профиль',
            icon: 'registr',
            menu: true
        }
    },
    {
        path: 'profile/edit',
        loadChildren: () => import('./pages/profile/edit/page.module').then(m => m.ProfileEditPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/edit_password',
        loadChildren: () => import('./pages/profile/edit_password/page.module').then(m => m.ProfileEditPasswordPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/addresses',
        loadChildren: () => import('./pages/profile/addresses/page.module').then(m => m.ProfileAddressesPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/address_edit',
        loadChildren: () => import('./pages/profile/address_edit/page.module').then(m => m.ProfileEditAddressPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/notifications',
        loadChildren: () => import('./pages/profile/notifications/page.module').then(m => m.ProfileNotificationsPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/orders',
        loadChildren: () => import('./pages/profile/orders/page.module').then(m => m.ProfileOrdersPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/orders/:orderId',
        loadChildren: () => import('./pages/profile/order/page.module').then(m => m.ProfileOrderPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/code',
        loadChildren: () => import('./pages/profile/code/page.module').then(m => m.ProfileCodePageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/commentaries',
        loadChildren: () => import('./pages/profile/commentaries/page.module').then(m => m.ProfileCommentariesPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'profile/comment',
        loadChildren: () => import('./pages/profile/comment/page.module').then(m => m.ProfileCommentPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'category',
        loadChildren: () => import('./pages/category/page.module').then(m => m.CategoryPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'category/:categoryId',
        loadChildren: () => import('./pages/category/page.module').then(m => m.CategoryPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'category/:categoryId/:subCategoryId',
        loadChildren: () => import('./pages/category/page.module').then(m => m.CategoryPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/main/page.module').then(m => m.OrderingMainModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/contacts',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/contacts/page.module').then(m => m.OrderingContactsModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/payment',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/payment/page.module').then(m => m.OrderingPaymentModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/order-check',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/order-check/page.module').then(m => m.OrderingOrderCheckModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/confirmation',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/confirmation/page.module').then(m => m.OrderingConfirmationModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/payment-3ds',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/payment-3ds/page.module').then(m => m.OrderingPayment3dsModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/finish',
        canActivateChild: [CartGuard],
        loadChildren: () => import('./pages/ordering/finish/page.module').then(m => m.OrderingFinishModule),
        data: {
            auth: true
        }
    },
    {
        path: 'ordering/finish/:orderId',
        loadChildren: () => import('./pages/ordering/finish/page.module').then(m => m.OrderingFinishModule),
        data: {
            auth: true
        }
    },
    {
        path: 'payment-rules',
        loadChildren: () => import('./pages/payment-rules-page/page.module').then(m => m.PaymentRulesModule),
        data: {
            auth: true
        }
    },
    {
        path: 'track',
        loadChildren: () => import('./pages/track/page.module').then(m => m.TrackPageModule),
    },
    {
        path: 'track/:orderId/:iikoId/:orderType',
        loadChildren: () => import('./pages/track/page.module').then(m => m.TrackPageModule),
    },
    {
        path: 'survey',
        loadChildren: () => import('./pages/survey/page.module').then(m => m.SurveyPageModule),
        data: {
            auth: true
        }
    },
    {
        path: 'cart',
        loadChildren: () => import('./pages/cart/page.module').then(m => m.CartPageModule),
    },
    {
        path: 'update',
        loadChildren: () => import('./pages/update/page.module').then(m => m.UpdatePageModule),
    },
    {
        path: 'city-selection',
        loadChildren: () => import('./pages/city-selection/page.module').then(m => m.CitySelectionModule)
    },
    {
        path: 'search-page',
        loadChildren: () => import('./pages/search-page/page.module').then(m => m.SearchPageModule),
        data: {name: 'SearchPage'}
    },
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        data: {
            auth: false
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
