import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AlertController} from '@ionic/angular';
import {LoadingController} from '@ionic/angular';
import {Observable, of} from 'rxjs';
import {catchError, retry, tap} from 'rxjs/operators';
import {AppService} from '../services/app.service';
import {RegJsonAnswer} from '../entity/regJsonAnswer.entity';
import {environment} from '../../environments/environment';

@Injectable()
export abstract class MainService {

    protected baseUrl = environment.baseUrl;
    protected apiUrl = environment.apiUrl;
    protected model: string;
    public url: string;
    public isLoading: boolean;
    // public version = 2;

    protected http: HttpClient;
    protected loader: LoadingController;
    protected appService: AppService;
    protected loading: any;
    protected alert: AlertController;

    public error = false;
    public errorMessage = false;

    public data: any[];

    private currentRequest: any;

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        this.http = http;
        this.alert = alert;
        this.loader = loader;
        this.appService = appService;
    }

    public getFullUrl(link) {
        return this.baseUrl + link;
    }

    async showLoading() {
        await this.appService.showLoading();
    }

    async hideLoading() {
        await this.appService.hideLoading();
    }

    protected regJsonRequest<T>(data: any, callback: (status: boolean, data: T) => void, message = false, loading = false) {
        this.postRequest(data, (answer: RegJsonAnswer & T) => {
            let status = true;
            if (typeof answer.sucess !== 'undefined') {

                /*
                * @TODO проверка ошибок
                * */
                if (answer.sucess) {

                } else {

                }

                status = answer.sucess;

            }

            callback(status, answer);

        }, 'personal/'+data.action, message, loading);
    }

    async getRequest(
        data: any,
        callback: (answer: any) => void,
        url: string = null,
        message: boolean = true,
        loading: boolean = true
    ) {

        if (loading) {
            await this.showLoading();
        }

        this.getServerRequest(this.getUrl(url), 'GET', data, loading).subscribe(res => {

            if (res) {
                this.handleSuccess(res, message, callback, loading);
            }
        }, (error) => {
            if (message) {
                this.handleError(error, loading);
            }
        });

    }

    async putRequest(
        data: any,
        callback: (answer: any) => void,
        url: string = null,
        message: boolean = false,
        loading: boolean = false
    ) {

        if (loading) {
            await this.showLoading();
        }

        this.postServerRequest(this.getUrl(url), 'PUT', data, loading).subscribe(res => {
            if (res) {
                this.handleSuccess(res, message, callback, loading);
            }
        }, (error) => {
            this.handleError(error, loading);
        });

    }

    async postRequest(
        data: any,
        callback: (answer: any) => void,
        url: string = null,
        message: boolean = true,
        loading: boolean = true
    ) {
        if (loading) {
            await this.showLoading();
        }
        // data.city_id = 0;

        //TODO must ideal solution not THIS
        var urlBuild;
        if (data.action && url.indexOf('/') == -1) {
            urlBuild = this.getUrl(url) + '/' + data.action;
        }

        this.postServerRequest(urlBuild ? urlBuild : this.getUrl(url), 'POST', data, loading).subscribe(res => {
            if (res) {
                this.handleSuccess(res, message, callback, loading);

            }
        }, (error) => {
            this.handleError(error, loading);
        });

    }

    private log(message: string) {
        console.log(message);
    }

    private async handleError(error: any, loading = false) {

        // this.appService.hideSplash();
        if (loading) {
            this.hideLoading();
        }

        // this.openErrorPopup();
        // this.appService.showMessage('Ошибка', 'Мы уже работаем над её устранением. Вы можете сделать заказ на сайте <a href="https://www.hatimaki.ru" target="_blank">https://www.hatimaki.ru</a>');
    }

    private handleSuccess(
        data,
        showMessage,
        callback: (answer: any) => void,
        loading = false,
    ) {
        if (loading) {
            this.hideLoading();
        }

        if (showMessage && data.message && data.result) {
            this.appService.showMessage(data.message);
        }

        callback(data);
    }

    public getUrl(url: string = null): string {

        if (url.includes('http://') || url.includes('https://')) {
            return url;
        } else {
            return this.baseUrl + this.apiUrl + (url ? url : '');
        }

    }

    // private createAuthorizationHeaderObject() {
    //     // return {"Authorization": "Bearer " + UserService.token};
    // }

    getServerRequest<T>(url: string, method: string = 'GET', data?, loading = false): Observable<T[]> {
        let params = new HttpParams();

        if (data !== null) {
            Object.keys(data).forEach((key) => {
                params = params.append(key, data[key]);
            });
        }

        if (method === 'GET') {
            return this.http.get<T[]>(url, {params});
            // .pipe(
            //     tap(_ => this.log('login')),
            //     catchError(this.handleDeepError('login', []))
            // );
        } else if (method === 'DELETE') {
            return this.http.delete<T[]>(url, {params});
            // .pipe(
            //     tap(_ => this.log('login')),
            //     catchError(this.handleDeepError('login', []))
            // );
        }
    }

    postServerRequest(url: string, method: string, data: any, loading): Observable<any[]> {

        // if (this.appService.getUserToken() && !data.id) {
        //     data.id = this.appService.getUserToken();
        // }

        if (this.appService.getGuestToken()) {
            data.guest = this.appService.getGuestToken();
        }

        return this.http.post<any[]>(url, data)
            .pipe(
                catchError(this.handleDeepError('login', [], loading))
            );
    }

    private handleDeepError<T>(operation = 'operation', result?: T, loading = false) {
        return (error: any): Observable<T> => {

            // this.appService.hideSplash();

            if (loading) {
                this.hideLoading();
            }

            // this.appService.showMessage('Ошибка', 'Мы уже работаем над её устранением. Вы можете сделать заказ на сайте <a href="https://www.hatimaki.ru" target="_blank">https://www.hatimaki.ru</a>');

            // this.openErrorPopup();

            return of(null);
        };
    }

    public async testRequest(callback: any) {
        await this.showLoading();

        setTimeout(() => {
            this.hideLoading();
            callback();
        }, 500);

    }

    private async makeAlert(subHeader, header) {
        if (header == null) {
            header = subHeader;
            subHeader = null;
        }

        await this.appService.showMessage(header, subHeader);
    }

    public findBy(field: string, value: string | number) {
        return this.data.find((item) => {
            return item[field].toString() === value?.toString();
        });
    }

    public findById(id: number) {
        return this.findBy('id', id);
    }

}
