import {NgModule} from '@angular/core';
import {CategoryItemComponent} from '../components/category-item/item.component';
import {ProductItemComponent} from '../components/product-item/item.component';
import {BlogItemComponent} from '../components/blog-item/item.component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {LazyItemComponent} from '../components/lazy-item/item.component';
import {NgxMaskModule} from 'ngx-mask';
import {PipesModule} from './pipes/pipes.module';
import {TimePickerComponent} from '../components/time-picker/time-picker.component';
import {ProductItemLightComponent} from '../components/product-item-light/item.component';
import {ProductItemSliderComponent} from '../components/product-item-slider/item.component';
import {PopupBirthdayComponent} from '../components/popups/popup-birthday/popup-birthday.component';
import {PopupSaveAddressComponent} from '../components/popups/popup-save-address/popup-save-address.component';
import {PopupUnavailableProductsComponent} from '../components/popups/popup-unavailable-products/popup-unavailable-products.component';
import {PopupSaveCommentComponent} from '../components/popups/popup-save-comment/popup-save-comment.component';
import {RecommendItemComponent} from '../components/recommend-item/recommend-item.component';
import {PopupDatePickerComponent} from '../components/popups/popup-date-picker/popup-date-picker.component';
import {PopupWorkTimeComponent} from '../components/popups/popup-work-time/popup-work-time.component';
import {PopupLoadErrorSurvey} from '../components/popups/popup-loaderror-survey/popup-loaderror-survey.component';
import {PopupSousesComponent} from '../components/popups/popup-souses/popup-souses.component';
import {PopupSousesListComponent} from '../components/popups/popup-souses-list/popup-souses-list.component';
import {PopupOrderFinishIikoError} from '../components/popups/popup-order-finish-iiko-error/popup.component';
import {SwiperModule} from 'ngx-swiper-wrapper';
import {PopupErrorComponent} from '../components/popups/popup-error/popup-error.component';
import {ModalOverlayComponent} from '../components/modal-overlay/modal-overlay.component';
import {PopupSelectAddressComponent} from '../components/popups/popup-select-address/popup-select-address';
import {DefaultAddressFormComponent} from '../components/default-address-form/default-address-form.component';
import {PopupTimePickerComponent} from '../components/popups/popup-time-picker/popup-time-picker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        LazyLoadImageModule,
        NgxMaskModule.forChild(),
        PipesModule,
        SwiperModule
    ],
    exports: [
        ProductItemComponent,
        BlogItemComponent,
        CategoryItemComponent,
        LazyItemComponent,
        ProductItemLightComponent,
        ProductItemSliderComponent,
        TimePickerComponent,
        PopupBirthdayComponent,
        PopupSaveAddressComponent,
        PopupSaveCommentComponent,
        PopupDatePickerComponent,
        PopupWorkTimeComponent,
        PopupLoadErrorSurvey,
        PopupSousesComponent,
        PopupSousesListComponent,
        RecommendItemComponent,
        PopupOrderFinishIikoError,
        PopupErrorComponent,
        ModalOverlayComponent,
        PopupUnavailableProductsComponent,
        PopupSelectAddressComponent,
        DefaultAddressFormComponent,
        PopupTimePickerComponent
    ],
    declarations: [
        CategoryItemComponent,
        ProductItemComponent,
        BlogItemComponent,
        LazyItemComponent,
        ProductItemLightComponent,
        ProductItemSliderComponent,
        TimePickerComponent,
        PopupBirthdayComponent,
        PopupSaveAddressComponent,
        PopupSaveCommentComponent,
        PopupDatePickerComponent,
        PopupWorkTimeComponent,
        PopupLoadErrorSurvey,
        PopupSousesComponent,
        PopupSousesListComponent,
        RecommendItemComponent,
        PopupOrderFinishIikoError,
        PopupErrorComponent,
        ModalOverlayComponent,
        PopupUnavailableProductsComponent,
        PopupSelectAddressComponent,
        DefaultAddressFormComponent,
        PopupTimePickerComponent
    ]
})
export class MainPageModule {
}
