import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../services/app.service';
import {MainItem} from '../../core/main.item';
import {NavigationService} from '../../services/navigation.service';
import { CatalogService } from 'src/app/models/catalog.service';


@Component({
    selector: 'category-item',
    templateUrl: 'item.html',
    styleUrls: ['category-item.scss']
})

export class CategoryItemComponent extends MainItem implements OnDestroy, OnInit {

    @Input() categoryId: any;
    @Input() subCategoryId?: any;

    appServices: AppService;
    private navigationService: NavigationService;
    catalogService: CatalogService;
    category: any;
    categoryName: string;

    constructor(
        appServices: AppService,
        navigationService: NavigationService,
        catalogService: CatalogService
    ) {
        super();

        this.appServices = appServices;
        this.navigationService = navigationService;
        this.catalogService = catalogService;
    }

    ngOnInit() {
        this.category = this.catalogService.findCategoryById(this.categoryId);

        if (this.subCategoryId) {
            this.category = this.category.subCategories.find(item => {
                if (item.id === this.subCategoryId) {
                    return true;
                }
            });
        }

        this.categoryName = this.category.name;
    }

    ngOnDestroy() {

    }

    goToCategory() {

        if (this.subCategoryId) {
            this.navigationService.goToPage(`category/${this.categoryId}/${this.subCategoryId}` , false);
        } else {
            this.navigationService.goToPage('category/' + this.categoryId, false);
        }
    }

}
