import {Injectable} from '@angular/core';
import {CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {CartService} from '../../models/cart.service';

@Injectable({
    providedIn: 'root'
})
export class CartGuard implements CanActivateChild {
    private cartService: CartService;

    constructor(cartService: CartService) {
        this.cartService = cartService;
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.cartService.cart.products.length > 0;
    }

}
