import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import {MainItem} from '../../core/main.item';
import {ProductModalService} from 'src/app/services/product-modal.service';
import {CatalogService} from 'src/app/models/catalog.service';
import {AppService} from 'src/app/services/app.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Product} from 'src/app/entity/product.entity';
import {CartService} from '../../models/cart.service';
import {Subscription} from 'rxjs';
import {ProductModalComponent} from '../product-modal/product-modal';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'product-item-light',
    styleUrls: ['./item.scss'],
    templateUrl: 'item.html'
})

export class ProductItemLightComponent extends MainItem implements OnDestroy, OnInit {

    // static readonly DishTypes = fromCore.DishTypes;
    // static readonly PizzaSizes = fromCore.PizzaSizes;
    // readonly self = Dish;

    @Input() dish: Product & { offerName: string };
    @Input() quantity: number;

    public productModalService: ProductModalService;
    cartService: CartService;
    catalog: CatalogService;
    appService: AppService;
    sanitization: DomSanitizer;
    subscriptions: Subscription[] = [];
    cdr: ChangeDetectorRef;
    modalService: ModalService;
    catalogService: CatalogService;

    constructor(
        cartService: CartService,
        catalog: CatalogService,
        productModalService: ProductModalService,
        appService: AppService,
        sanitization: DomSanitizer,
        cdr: ChangeDetectorRef,
        modalService: ModalService,
        catalogService: CatalogService
    ) {
        super();

        this.cdr = cdr;
        this.cartService = cartService;
        this.catalog = catalog;
        this.productModalService = productModalService;
        this.appService = appService;
        this.sanitization = sanitization;
        this.modalService = modalService;
        this.catalogService = catalogService;
    }

    ngOnInit() {
        this.subscriptions.push(this.cartService.updateQuantity$.subscribe(() => {
            this.qtyInCart();
        }));
        this.subscriptions.push(this.catalogService.updateStopList$.subscribe(() => {
            this.cdr.detectChanges();
        }));
    }

    addQty(event: Event) {
        event.stopPropagation();
        this.quantity = this.cartService.addToCart(this.dish, +1, this.dish.offerId);
        this.productModalService.updateItemQuantityInMenu();
    }

    removeQty(event: Event) {
        event.stopPropagation();
        this.quantity = this.cartService.addToCart(this.dish, -1, this.dish.offerId);
        this.productModalService.updateItemQuantityInMenu();
    }

    qtyInCart() {
        if (this.dish.offerId) {
            this.quantity = this.cartService.getQty(this.dish.id, this.dish.offerId);
        } else {
            this.quantity = this.cartService.getQty(this.dish.id);
        }

        this.cdr.detectChanges();
    }

    getPrice(typePrice: string) {

        const product: Product = new Product();
        Object.assign(product, this.dish);

        if (product.offers.length && product.offerId.toString() !== '0') {
            return product.getPrice(1, typePrice);
        } else {
            return product.getPrice(1, typePrice);
        }
    }

    getDishName(item) {
        return item.name.replace(/&quot;/g, '"');
    }

    getBackgroundImageCss(imageUrl: string) {
        return this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }

    onDishClick() {
        this.productModalService.openProductModal(this.dish);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions.length = 0;
    }

    getIsStopList(offerId: number = null) {
        return this.dish.isStopList || (offerId != null && this.dish.offers.find(o => o.id === offerId)?.isStopList);
    }
}
