import {Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {SwiperComponent, SwiperConfigInterface} from 'ngx-swiper-wrapper';

@Component({
    selector: 'app-popup-date-picker',
    templateUrl: './popup-date-picker.component.html',
    styleUrls: ['./popup-date-picker.component.scss'],
})
export class PopupDatePickerComponent implements OnInit {
    private modalController: ModalController;
    text: any = 'text from server';

    @Input() value;
    dList = [];
    dIndex = 0;
    mIndex = 0;
    yIndex = 0;
    mList = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    yList = [];

    private dSlider;
    private mSlider;
    private ySlider;
    SliderConfig: SwiperConfigInterface = {
        direction: 'vertical',
        slidesPerView: 'auto',
        centeredSlides: true,
        freeMode: true,
        freeModeMomentum: true,
        freeModeSticky: true,
        freeModeMomentumVelocityRatio: 1,
        freeModeMomentumRatio: 0.2,
        nested: true,
    };

    constructor(
        modalController: ModalController
    ) {
        this.modalController = modalController;
    }

    ngOnInit() {
        this.yList = this.getArray(-1, new Date().getFullYear(), new Date().getFullYear() - 121);
        this.dList = this.getArray(1, 1, 30);
        if (this.value) {
            const t = this.value.split('.');
            if (!isNaN(Number(t[0])) && !isNaN(Number(t[1])) && Number(t[1]) > 0 && Number(t[1]) < 13 && !isNaN(Number(t[2]))) {
                const findD = this.dList.findIndex(item => Number(item) === Number(t[0]));
                const findM = Number(t[1]) - 1;
                const findY = this.yList.findIndex(item => Number(item) === Number(t[2]));
                if (findD > -1 && findD > -1 && findD > -1) {
                    this.setYear(findY);
                    this.setMonth(findM);
                    this.setDay(findD);
                } else {
                    this.setYear(0);
                    this.setMonth(0);
                    this.setDay(0);
                }
            }
        } else {
            this.setDay(0);
            this.setMonth(0);
            this.setYear(0);
        }
    }

    close() {
        this.modalController.dismiss({
            action: 'close',
            date: this.value
        });
    }

    save() {
        this.modalController.dismiss({
            action: 'save',
            date: `${this.dList[this.dIndex]}.${+this.mIndex + 1 > 9 ? +this.mIndex + 1 : '0' + (+this.mIndex + 1)}.${this.yList[this.yIndex]}`
        });
    }


    getArray(step: number, start: number, end: number): string[] {
        const list = [];
        for (let i = start; i * step <= end * step; i += step) {
            list.push(i >= 10 ? i.toString() : '0' + i.toString());
        }
        return list;
    }

    updateDaysList() {
        const oldNumber = this.dList[this.dIndex];
        const days = new Date(this.yList[this.yIndex], this.mIndex + 1, 0).getDate();
        this.dList = this.getArray(1, 1, days);
        const restoreIndex = this.dList.findIndex(item => item === oldNumber);
        if (this.dSlider) {
            this.dSlider.directiveRef.update();
        }
        if (restoreIndex > -1) {
            this.setDay(restoreIndex);
        } else {
            this.setDay(0);
        }
    }

    setDay(index) {
        if (index < this.dList.length && index >= 0) {
            this.dIndex = index;
            if (this.dSlider) {
                this.dSlider.directiveRef.swiper().slideTo(this.dIndex);
            }
        }
    }

    setMonth(index: number) {
        if (index < this.mList.length && index >= 0) {
            this.mIndex = index;
            if (this.mSlider) {
                this.mSlider.directiveRef.swiper().slideTo(this.mIndex);
            }
            this.updateDaysList();
        }
        // this.valueChange.emit(this.hoursList[this.hIndex] + ':' + this.minutesList[this.mIndex]);
    }

    setYear(index: number) {
        if (index < this.yList.length && index >= 0) {
            this.yIndex = index;
            if (this.ySlider) {
                this.ySlider.directiveRef.swiper().slideTo(this.yIndex);
            }
            this.updateDaysList();
        }
        // this.valueChange.emit(this.hoursList[this.hIndex] + ':' + this.minutesList[this.mIndex]);
    }

    dSliderInit(slider) {
        this.dSlider = slider;
        this.dSlider.config = this.SliderConfig;
        this.dSlider.directiveRef.swiper().slideTo(this.dIndex, 0);
        this.dSlider.directiveRef.update();
    }

    mSliderInit(slider) {
        this.mSlider = slider;
        this.mSlider.config = this.SliderConfig;
        this.mSlider.directiveRef.swiper().slideTo(this.mIndex, 0);
        this.mSlider.directiveRef.update();
    }

    ySliderInit(slider) {
        this.ySlider = slider;
        this.ySlider.config = this.SliderConfig;
        this.ySlider.directiveRef.swiper().slideTo(this.yIndex, 0);
        this.ySlider.directiveRef.update();
    }

    dSliderChange(index) {
        this.dIndex = index;
    }

    mSliderChange(index) {
        this.mIndex = index;
    }

    ySliderChange(index) {
        this.yIndex = index;
    }

}
