import { Entity } from '../core/main.entity';

export class Promotion implements Entity {
    id: number;
    title: string;
    text: string;
    detail: string;
    imageUrl: string;
    promoCode: string;          // получить промокод
    defaultImage: string;
    imageUrlCard: string;
    titleColor: string;
    slug: string;
}
