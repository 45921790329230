import {HelperService} from '../services/helper.service';

export abstract class MainItem {

    constructor() {
    }

    getLazyDefaultImage(id) {
        return HelperService.getLazyDefaultImage(id);
    }
}
