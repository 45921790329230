import {Injectable} from '@angular/core';
import {MainService} from '../core/main.service';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {AppService} from '../services/app.service';
import {Order} from '../entity/order.entity';
import {OrderReviewStatuses, OrderStatuses} from '../entity/lists';
// import {filter} from 'rxjs/operators';
import {ApiResponse} from '../entity/apiResponse.entity';

@Injectable({
    providedIn: 'root',
})

export class HistoryService extends MainService {

    public ordersCount = 0;
    public data: Order[] = [];
    public ordersWithSurvey: Order[] = [];
    public completedSurvey: Order[] = [];
    public notCompletedSurvey: Order[] = [];

    public limit = 15;

    constructor(
        http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService
    ) {
        super(http, alert, loader, appService);
    }

    public getData(page = 1, firstCall = false, callback: (status: boolean) => void, loading = false) {

        // if (firstCall || !firstCall) {
        this.postRequest({
            limit: this.limit,
            page,
        }, (response: ApiResponse) => {

            if (firstCall) {
                this.data = response.result.historyOrders;
            } else {
                this.data = [...this.data, ...response.result.historyOrders];
            }

            this.ordersCount = response.result.count;

            this.getOrdersWithSurvey();
            callback(true);

        }, 'order/history', false, loading);
        // } else {
        //     callback(true);
        // }

    }

    clean() {
        this.data = [];
    }

    public cleanOrders() {
        this.data = this.data.slice(0, this.limit);
    }

    getOrdersWithSurvey() {
        this.ordersWithSurvey = this.data.filter(order => {
            if (order.status === OrderStatuses.Completed && order.reviewId) {
                return true;
            }
        });

        this.completedSurvey = this.ordersWithSurvey.filter(order => {
            if (order.reviewStatus === OrderReviewStatuses.Completed) {
                return true;
            }
        });

        this.notCompletedSurvey = this.ordersWithSurvey.filter(order => {
            switch (order.reviewStatus) {
                case OrderReviewStatuses.None:
                case OrderReviewStatuses.Writing:
                    return true;
            }
        });
    }

    public cancelOrder(callback: (status: boolean) => void) {

    }

    public updateOrder(callback: (status: boolean) => void) {

    }
}
