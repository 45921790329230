import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-popup-souses',
    templateUrl: './popup-souses.component.html',
    styleUrls: ['./popup-souses.component.scss'],
})
export class PopupSousesComponent implements OnInit {
    private modalController: ModalController;
    text: any = 'text from server';
    textIsOpen = false;

    constructor(
        modalController: ModalController,
    ) {
        this.modalController = modalController;
    }

    ngOnInit() {
    }

    close() {
        this.modalController.dismiss({action: 'close'});
    }

    buy() {
        this.modalController.dismiss({action: 'buy'});
    }

    onToggleButton() {
        this.textIsOpen = !this.textIsOpen;
    }
}
