import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef
} from '@angular/core';
import {AppService} from '../../services/app.service';
import {MainItem} from '../../core/main.item';
import {SwiperConfigInterface} from 'ngx-swiper-wrapper';
import {CartService} from 'src/app/models/cart.service';
import {Product} from '../../entity/product.entity';
import {Promotion} from 'src/app/entity/promotion.entity';
import {DomSanitizer} from '@angular/platform-browser';
import {AnalyticsService} from '../../services/analytics.service';
import Scrollbar from 'smooth-scrollbar';
import {ModalService} from '../../services/modal.service';
import CustomScrollBarPlugin from '../../core/CustomScrollBarPlugin';

@Component({
    selector: 'modal-promo-item',
    styleUrls: ['./modal-promo-item.scss'],
    templateUrl: 'modal-promo-item.html'
})

export class ModalPromoItemComponent extends MainItem implements OnDestroy, OnInit {

    @Input() promo: Promotion;

    @ViewChild('scrollContainer', {static: false})
    scrollContainer;

    @ViewChild('shadow', {static: false})
    shadowRef: ElementRef;

    @ViewChild('promoDescription', {static: false})
    promoDescriptionRef: ElementRef;

    @ViewChild('selectSideDish', {static: false})
    selectSideDishRef: ElementRef;

    public recommendProducts: Product[] = [];

    private sanitization: DomSanitizer;
    public appServices: AppService;
    private cartService: CartService;
    private modalService: ModalService;

    public copyCode = false;

    scrollY = 70;
    opacity = 0;
    infoOpacity = 0;
    modalItemTop = 0;
    isIosParallax: boolean;
    private scrollBar: Scrollbar = null;


    constructor(
        appServices: AppService,
        cartService: CartService,
        sanitization: DomSanitizer,
        modalService: ModalService,
    ) {
        super();

        this.appServices = appServices;
        this.sanitization = sanitization;
        this.cartService = cartService;
        this.modalService = modalService;
        this.onScroll = this.onScroll.bind(this);
    }

    public recommendConfig: SwiperConfigInterface = {
        direction: 'horizontal',
        slidesPerView: 'auto',
        nested: false,
        touchMoveStopPropagation: true,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: false,
        pagination: false
    };

    ngOnInit() {
        AnalyticsService.pageVisit('Акции', this.promo.title);
        this.getParallax();
        setTimeout(() => {
            this.createVirtualScroll(this.scrollContainer.nativeElement);
        });
    }

    createVirtualScroll(targetElement) {
        Scrollbar.use(CustomScrollBarPlugin);
        this.scrollBar = Scrollbar.init(
            targetElement,
            {
                damping: 0.1,
                plugins: {
                    myPlugin: {
                        isModalGesture: () => this.modalService.isGesture
                    }
                }
            });
        this.scrollBar.addListener(this.onScroll);
        this.onScroll({offset: {x: 0, y: 0}});
    }

    copyPromoCode() {
        this.copyCode = true;

        this.cartService.cart.promo.code = this.promo.promoCode;

        setTimeout(() => {
            this.copyCode = false;
        }, 2000);
    }

    onScroll(e: any) {
        const selector = '.parallax-container';
        const imgContainer = this.scrollBar.contentEl.querySelector(selector) as HTMLElement;
        if (imgContainer) {
            let scroll = e.offset.y;
            scroll = scroll > 230 ? 230 : scroll;
            scroll = scroll < 0 ? 0 : scroll;
            const imgMargin = scroll / 2;
            imgContainer.style.backgroundPosition = '50% calc(50% + ' + imgMargin / 2 + 'px)';
        }

        this.modalItemTop = e.offset.y;
        this.setStyleCardImg();
        this.modalService.scrollModal(this.modalItemTop);
    }

    ngOnDestroy() {
        if (this.scrollBar) {
            this.scrollBar.removeListener(this.onScroll);
            this.scrollBar.destroy();
            this.scrollBar = null;
        }
    }


    setStyleCardImg() {
        this.shadowRef.nativeElement.style.setProperty('opacity', `${this.modalItemTop / 100}`);
        this.promoDescriptionRef.nativeElement.style.setProperty('opacity', `${1 - this.modalItemTop / 100}`);
    }

    getParallax() {
        const modalContent = document.querySelector('.modal-content') as HTMLElement;
        this.isIosParallax = this.supportCSSStyle('-webkit-overflow-scrolling', modalContent);
    }

    supportCSSStyle(prop: string, modalContent: HTMLElement) {
        return prop in modalContent.style;
    }

    getBackgroundImageCss(imageUrl: string) {
        return this.sanitization.bypassSecurityTrustStyle(`url(${imageUrl})`);
    }
}
