export enum Cities {
    Moscow,
    Ryazan
}

export enum CommentsStatuses {
    None,
    Loading,
    Loaded
}

export enum DeliveryType {
    Pickup = 0,
    Courier = 1
}

export enum Gender {
    Men = 0,
    Women = 1,
}

export enum ItemTypes {
    Basic,
    Pizza,
    WokConstructor
}

export enum OrderStatuses {
    WaitingForCall = 0,
    Cooking = 1,
    InAssembly = 2,
    InDelivery = 3,
    Completed = 4,
    Error = 5,
    Cancel = 6,
}

export enum OrderReviewStatuses {
    None = 0,
    Writing = 1,
    Completed = 2
}

export enum PizzaSizes {
    Small = 0,
    Large = 1
}

export enum ProductType {
    Basic = 0,
    Pizza = 1,
    Drink = 2,
    Pie = 3
}

export enum ReviewDishesRatingRecordTypes {
    Basic,
    Pizza
}

export enum CategoryTypes {
    Single = 0,
    Multiple = 1
}

export enum PaymentStatus {
    NotPayed = 0,
    Payed = 1
}

export enum Bills {
    FiveThousand = 0,
    TwoThousand = 1,
    OneThousand = 2
}