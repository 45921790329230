import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppService} from '../../services/app.service';
import {environment} from '../../../environments/environment';
import {takeUntil} from 'rxjs/operators';
import {CancelHttpService} from '../../services/cancel-http.service';

@Injectable()
export class MainInterceptor implements HttpInterceptor {

    constructor(
        private appService: AppService,
        private cancelHttpService: CancelHttpService
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.url.includes(environment.baseUrl)) {

            const token = localStorage.getItem('ru.z2s.app__token');

            let type = 'browser';

            if (this.appService.isCordova()) {
                type = this.appService.isIos() ? 'ios' : 'android';
            }

            if (token !== 'null' && token !== 'undefined' && token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: 'Bearer ' + token,
                    }
                });
            }

            request = request.clone({
                setHeaders: {
                    Device: type + '-' + this.appService.appVersionNumber,
                }
            });

            request = request.clone({
                setHeaders: {
                    DeviceToken: this.appService.deviceId,
                }
            });
        }
        request = request.clone({url: request.url[request.url.length - 1] === '/' ? request.url : request.url + '/'});
        if (request.url.includes('cart.php')) {
            return next.handle(request).pipe(takeUntil(this.cancelHttpService.onCancelPendingRequests()));
        }

        return next.handle(request);
    }
}
