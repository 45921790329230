import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
    selector: 'app-input-item',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class InputItemComponent implements OnInit {

    @Output() valueChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter<string>();

    @Input() value: string;
    @Input() label: string;
    @Input() name: string;
    @Input() maxlength: number;
    @Input() minlength: number;
    @Input() pattern: string;
    @Input() inputMode: string;
    @Input() id: string;
    @Input() placeholder = '';
    @Input() mark = true;
    @Input() mask: string;
    @Input() dropSpecialCharacters: boolean;
    _type = 'text';
    @Input() set type(v) {
        if (v === 'password') {
            this.isPassword = true;
        } else {
            this.isPassword = false;
            this._type = v;
        }
        this.updateInput();
    }

    get type() {
        return this._type;
    }

    @Input() spellcheck = false;
    @Input() autocapitalize = 'off';
    @Input() required = false;
    @Input() readonly = false;
    @Input() disabled = false;
    @Input() autofocus = false;
    @Input() autocomplete = 'off';
    @Input() auth = false;

    @ViewChild('field') field;

    public isPassword = false;
    public email = false;
    public isTypePhone = false;
    public customPatterns = {
        'c': {pattern: new RegExp('\[0-6,9\]')},
        '0': {pattern: new RegExp('\[0-9\]')}
    };

    ngOnInit() {
        if (this.type === 'email') {
            this.email = true;
        }

        if (this.type === 'password') {
            this.isPassword = true;
        }

        if (this.type === 'tel') {
            this.isTypePhone = true;
        }

        if (this.label) {
            this.placeholder = ' ';
        }
    }

    ngAfterViewInit() {
        this.updateInput();
    }

    private updateInput() {
        if (this.field && this.inputMode) {
            this.field.nativeElement.setAttribute('inputmode', this.inputMode);
        }
    }
}
