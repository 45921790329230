import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';


import {LazyLoadImageModule} from 'ng-lazyload-image';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { BannerModalComponent } from './banner-modal';
import { MainPageModule } from 'src/app/core/page.module';
import { PipesModule } from 'src/app/core/pipes/pipes.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild([
            {
                path: '',
                component: BannerModalComponent
            }
        ]),
        LazyLoadImageModule,
        MainPageModule,
        SwiperModule,
        PipesModule
    ],
    exports: [
        BannerModalComponent
    ],
    declarations: [
        BannerModalComponent
    ]
})
export class BannerModalModule {

}
