import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import {CartService} from '../../models/cart.service';
import {ProductModalService} from 'src/app/services/product-modal.service';
import {CatalogService} from 'src/app/models/catalog.service';
import {AppService} from 'src/app/services/app.service';
import {NavigationService} from 'src/app/services/navigation.service';
import {Router} from '@angular/router';
import {MenuPage} from '../../pages/menu/page';
import {ProductItemComponent} from '../product-item/item.component';
import {ModalService} from '../../services/modal.service';

@Component({
    selector: 'product-item-slider',
    styleUrls: ['../product-item/item.scss'],
    templateUrl: '../product-item/item.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ProductItemSliderComponent extends ProductItemComponent implements OnDestroy, OnInit {

    menuPage: MenuPage;

    constructor(
        cartService: CartService,
        cdr: ChangeDetectorRef,
        catalogService: CatalogService,
        productModalService: ProductModalService,
        modalService: ModalService,
        appService: AppService,
        navigationService: NavigationService,
        router: Router,
        menuPage: MenuPage
    ) {
        super(cartService,
            cdr,
            catalogService,
            productModalService,
            modalService,
            appService,
            navigationService,
            router);

        this.menuPage = menuPage;
    }

    preventSwipe(status) {
        // console.log('lockSwipeVar', status);
        this.menuPage.menuNgSlider.directiveRef.swiper().allowTouchMove = !status;
    }

}
