import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {ControlContainer, NgForm} from '@angular/forms';
import {AppService} from '../../../services/app.service';

@Component({
    selector: 'app-radio-item',
    templateUrl: 'item.html',
    styleUrls: ['item.scss'],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})

export class RadioItemComponent implements OnDestroy, OnInit {

    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();

    @Input() label: string;
    @Input() name: string;
    @Input() id: string;
    @Input() required = false;
    @Input() checked;

    public appService: AppService;
    constructor(
        appService: AppService,
    ) {
        this.appService = appService;
    }

    ngOnInit() {
    }

    ngOnDestroy() {

    }

    showLink(link) {
        window.open(link, '_system', 'location=yes');
    }
}
